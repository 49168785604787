.spinner {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-self: center;
    z-index: 8999;
    /* top: calc(50% - 4rem);
    left: calc(50% - 5rem); */
    /* margin-top: calc(50%); */
    margin-top: calc(50vh - 30px);
}

.full-screen {
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8999;
}
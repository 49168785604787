.item_groups_col {
    min-width: 100%;
   
}

.itemgroup_modal_row {
    padding: 1rem;
}

@media only screen and (min-width: 577px) {
    .itemgroup_modal_row {
        margin-top: 3rem;
    }
}
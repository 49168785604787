
.yellow {
    background: yellow;
    justify-self: left;
    min-width: 1500px;
    display: block;
}

.back-button {
    color: var(--nav-font-color);
    border: 1px solid var(--nav-font-color);
  }
  



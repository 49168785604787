.result-container {
    padding: 2px 16px;
    font-weight: 600;
}

.search-results {
    padding: 0;
}

.result {
    border-bottom: 1px solid var(--table_border);
    transition: 0.2s;
    border-radius: 0%;
    min-height: 4rem;
    display: flex;
    position: relative;

    /* background: var(--tab_background); */
}

.result-item-type {
    color: white;
    padding: 1em;
    position: absolute;
    align-items: center;
    text-align: right;
    right: .1rem;
    height: 100%;
}

.result:hover {
    background: var(--table_selected_item_background);
    cursor: pointer;
}

/* .result:hover {
    border-style: solid;
    border-color: var(--actionbar_background);
} */

/* result type backgrounds */
.custBack {
    background: rgb(235, 219, 0);
    color: rgb(73, 73, 73);
}

.itemBack {
    background: rgb(36, 120, 172);
}

.poBack {
    background: firebrick
}

.salesBack {
    background: lightgreen
}

.returnBack {
    background: plum;
}

.vendBack {
    background: darkslateblue;
}

.defaultBack {
    background: orangered;
}

.no-type {
    background: transparent !important;
}
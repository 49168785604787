.item-detail-image-container {
    align-items: center;
    text-align: center;
    height: 86px;
    border: 1px solid var(--field_border);
    border-radius: .25rem;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
}

.item-detail-image-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.item-detail-image-col {
   padding-top: .5rem;
   display: flex;
   justify-content: flex-start;
}

.item-detail-image-container svg {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.col_test1 {
    display: flex;
    justify-content: flex-end;
    max-width: 185px;
}

.item_description_image_row {
    justify-content: space-between;   
}

.item_taxable {
    display: flex;
    align-content: flex-end;
}

.item-detail-image-container {
    display: flex;
    justify-content: space-around;
    align-self: flex-end;
    margin-bottom: 1rem;
}

.item_description_image_row {
    display: flex;
    justify-content: space-between;
}

.item-detail-image-col {
    display: flex;
    justify-content: flex-end;
}

.item_camera_icon {
    align-self: center;
    stroke: var(--field_border);
}

.item_camera_icon_small_div {
    min-width: 8rem;
    max-height: 7rem;
    min-height: 7rem;
    display: flex;
    justify-content: center;
    border: 1px solid var(--field_border);
    border-radius: .4rem;
}

.datepicker_input_field {
    min-width: 200px;
    height: 37px;
    border-radius: .2rem;
    border: 1px solid var(--field_border);
}



.tab_with_locationtree_opened.active {
  background: var(--background_shade) !important;
}


#itemInfoForm {
  /* height: calc(95vh - 3.5rem); */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* min-height: auto; */

}

@media only screen and (max-width: 576px) {

  .item_modal {
    overflow-y: hidden;
  }
}

.item_modal .tab_content {
  overflow-y: auto;
}

@media only screen and (max-height: 576px) {
  #itemInfoForm {
    height: calc(100vh - 6px);
  }
}


.tree-control-outer-container {
    height: calc(100% - (var(--toolbar-height)));
    overflow: auto;
    border-top: 1px solid var(--table_border);
}

.tree-control {
    border-top: none;
    background: var(--canvas-color);
}

.tree-control-list-item {
    padding-left: 1rem;
    align-items: center;
    border: none;
    padding-top: 0;
    padding-bottom: .5rem;
    color: var(--canvas_font);
}

.tree-control-item-text  {
    padding-left: .5rem;
    padding-right: .5rem;
    display: inline;
}

.tree-control-expand-box {
    display: flex;
    margin-right: .5rem;
    /* color: var(--canvas_font); */

}


.tree-item-icon-text {
    display: flex;
    align-self: center;
    align-items: center;
}

.tree-control-add-item {
    margin-top: 0;
    font-style: italic;
    padding-left: 1rem;
    font-size: smaller;
}

.selected-tree-list-item {
    background: var(--table_selected_item_background) !important
}

@media only screen and (max-width: 576px) {
    .tree-control-outer-container {
        height: calc(100% - (var(--toolbar-height) + var(--tablet-toolbar-height)));
        overflow: auto;
    }
}
.item_input_unit_selection_control {
    display: flex;
    flex-wrap: nowrap;
}

.item_input_unit_selection {
    width: 100%;
}

.item_input_unit_selection_control.input-group {
    min-width: 100%;
}
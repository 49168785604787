
.windowed_table_container {
    height: calc(100% - 2.2rem);
}

.windowed_table_table_cell {
    text-align: left;
}

.windowed_table_container_noHeader {
    height: calc(100% - .5rem);
}



.windowed_table_container_div {
    width: 100%;
    border-collapse: collapse ;
    background: var(--canvas_background);
    padding: 0;
    overflow-y: overlay !important;
}

.windowed_table_container_div > div {
    display: table;
    width: 100%;
    position: relative !important;
}

.windowed_table_icon_col {
    max-width: 3rem;
}

.windowed_table_icon_col_data {
    max-width: 3rem;
}

.windowed_table_table_row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    width: 100%;
    border: 1px dotted var(--table_border) !important;
    border-collapse: collapse;
    background: var(--canvas_background) !important;
    color: var(--canvas_font) !important;
    padding: 0 .5rem;
    /* margin-right: 15px !important; */
}

.windowed_table_table_row span {
    text-overflow: ellipsis;
    padding-right: .5rem;
}

.windowed_table_table_row.selected {
    background: var(--table_selected_item_background) !important;
    color: var(--table_selected_item_font) !important;
}

.windowed_table_table_cell {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    min-width: 0;
    padding: 0;
    vertical-align: top;
    background: transparent;
}


.windowed_table_table_cell > div { /*targets the div that contains returned data*/
    flex-basis: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    /* margin-left: .5rem; */
}


.windowed_table_table_cell:first-child {

    /* This will set the first column to have 40% of the table
    Using first column only seems to not work well
    need to establish a class for PRIMARY COLUMN */
    /* flex-basis: 40%; */

}

.windowed_table_table_row:hover {
    /* color: var(--window-table-hover-forecolor);  */
    /* background-color: var(--window-table-hover-color); */
}



/* Headers  */


.windowed_table_table_row_header {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    text-overflow: ellipsis;
    padding: 0 .5rem;
    margin-right: 15px;
    vertical-align: bottom;
    font-weight: bold;
    align-items: center;
    border-radius: .25rem;
    border-bottom: 1px solid var(--media-table-border-color);
    border-top: 1px solid var(--media-table-border-color);
    background: var(--table_column_header_background);
    color: var(--table_column_header_font);
    height: 2.2rem;
}
/* End Headers */




/* Tooltip for icons */
.icon-tooltip {
    display: flex;
     position: absolute;
    /*display: inline-block; */
    /* border-bottom: 1px dotted black; */
  }

  .icon-tooltip .tooltiptext {
    visibility: hidden;
    width: 1px;
    background: var(--canvas-color);
    color: var(--font-color-oncanvas);
    border: 1px solid var(--table-border-color);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: relative;
    z-index: 11;
    bottom: 100%;
    left: 50%;
    /* margin-left: -60px; */
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
    top: .5rem;
    left: .25rem;
    height: 100%;
  }

  /* .icon-tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  } */

  .windowed_table_icon_div {
      width: 0px;
  }

  .windowed_table_icon_div:hover .tooltiptext {
    /* width: 140px; */
    padding: .25rem;
    visibility: visible;
    opacity: 1;
  }

  /* Sorter Div, holds all mobile size sorting capacity */
  .windowed_table_column_sorter {
    display: none;
  }






/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
  /* I removed this media query and repaced it with the one below   */
  @media
only screen and (max-width: 760px)
/* (min-device-width: 768px) and (max-device-width: 1024px)    */


/* @media only screen and (max-width: 1000px)  */
{
   /* Force table to not be like tables anymore */
   .windowed_table_container {
        height: 100%;
        border: 1px solid var(--table-border-color);
        padding-bottom: 0;
    }

    .colony_window_table_tr {
        text-transform: none !important;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .windowed_table_table_row_header {
        position: absolute;
        top: -9999px;
        left: -9999px;
        padding: 0 .5rem;
    }

    .windowed_table_container_div {
        padding: 1rem 0;
    }

    .windowed_table_table_row {
        border-radius: .25rem;
        width: 100%;
        margin-bottom: .25rem;
        display: grid !important ;
    }

    .colony_display_table th {
        border: none !important;
    }

    .windowed_table_table_cell {
        flex: 1 1 760 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;
        display: inline;
        margin: auto .5rem;
    }

    .windowed_table_table_cell div {
        display: inline;
    }

    .windowed_table_table_text_cell::before {
        position: relative;
        content: attr(th-data);
        color: var(--font-color-oncanvas);
        font-weight: 600;
    }

    .windowed_table_table_icon_cell {
        max-width: 100% !important;
    }

    .windowed_table_table_icon_cell::after {
        position: relative;
        content:attr(th-data);
        color: var(--font-color-oncanvas);
        font-weight: 500;
        vertical-align: -10%;
        /* Makes it look like a link */
        cursor: pointer;
        color: var(--fake-link-color);
        text-decoration: none;
        background: transparent;
      
    }

    .windowed_table_table_icon_cell::after:hover {
        text-decoration: underline;
    }

    .windowed_table_table_icon_cell div {
        padding-left: 0px;
        padding-right: 1.75rem;
    }

    .text-center {
        text-align: left !important;
    }

    .windowed_table_column_sorter {
        display: flex;
        justify-content: flex-end;
    }

    .windowed_table_floating_sort_button {
        position: absolute;
        z-index: 2;
        margin-top: 0;
        width: 2rem;
        height: 2rem;
        padding: 0;
    }

    .windowed_table_sort_list_container {
        background: var(--canvas-color);
        position: absolute;
        float: right;
        top: 2rem;
        right: 2px;
        z-index: 200;
        max-height: calc(40VH);
        overflow-y: scroll;
        overflow: auto;
        border: 1px solid var(--control-border-color);
    }

    .windowed_table_table_cell > div::before {
        content: ": ";
        font-weight: bold;
    }


}
.users_selected_user {
    background: var(--table_selected_item_background) !important;
    color: var(--table_selected_item_font) !important;
}

.user_page_container {
    overflow-y: auto !important;
    overflow-x: hidden;
}

.user_cards {
    border: 1px solid var(--table_column_header_border);
    background: var(--canvas_background);
    color: var(--canvas_font);
    max-width: 600px;
    min-height: 8rem;
    margin: 1rem;
}

.user_cards .card_header {
    background: var(--table_column_header_background);
    border-radius: .25rem .25rem 0 0;
    padding: .5rem;
    font-weight: 600;
}

.user_cards .cardRow {
    padding: .5rem;
}

.user_cards .cardRowName {
    display: flex;
    justify-content: space-between;
}
.user_form {
    padding: 1rem 1rem 0 1rem;
}
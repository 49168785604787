.returnSummary {
  height: 7rem; 
  background: var(--table_column_header_background);
}

.masterCheckbox input {
  top: 0 !important;

}


.header .row {
  display: flex;
  align-items: center;
}

.returnSummary .col {
  margin: .25rem;
}

.returnSummary p {
  margin: 0;
 }

.returnSummary .customer p {
  text-align: right;
}

.returnSummary div.form-group {
 width: 100%;
 margin-bottom: 0;
}

@media only screen and (max-width: 576px) {

  .returnSummary .row {
    margin: 0 .5em;
  }
  .returnSummary .col {
    margin: .1rem;
    padding: .1rem
  }

  .SalesOrderName {
    max-width: 33%;
  }
}
.profile_settings_modal {
    min-width: 100vw !important;
    min-height: 100vh !important;
}

.profile_settings_modal .modal-dialog {
    min-width: 100%;
    top: 35px;
    min-width: 100vw;
    max-height: 23rem;
}

.profile_settings_modal .modal-content {
    border: 2px solid var(--actionbar_background);
    margin-right: 1rem;
    background: var(--canvas_background);
}
.profile_settings_modal div {
    max-width: 250px !important;
}

.profile_settings_modal > div {
    height: 500px;
    top: 20px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    position: relative
}

.profile_settings_modal .modal-content > div {
    max-width: 350px;
    max-height: 25rem; /*allows profile options to be accessed when mobile devices are in landscape*/
}

.profile_overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: var(--canvas_background_blur);
    filter: opacity(.6);
}

.profile_bubble {
    background: var(--actionbar_background);
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--navbar_font);
}

.profile_bubble h2 {
    margin: auto;
}

.profile_options {
    min-height: 100%;
    right: 0;
    border-radius: 3px 0 0 3px;
    display: flex;
    flex-direction: column;
    z-index: 99;
    overflow: hidden;
}

.profile_options .profile_open {
    transform: translateX(0);
}

.profile_title {
    font-size: 1.25em;
    height: 50px;
    color: var(--canvas_font);
    padding: .5rem;
    background: var(--table_column_header_background);
    border-radius: 6px 6px 0 0;
}

.profile_title span {
    position: absolute;
    right: .5rem;
    font-size: .7em;
    cursor: pointer;
    color: var(--canvas_font) !important;
}

.theme_toggle_container,
.dashboard_option_container,
.alerts_option_container,
.admin_settings_container,
.logout_container {
    color: var(--canvas_font);
    width: 100%;
    height: 50px !important;
    display: flex;
    align-items: center;
    padding: 0 .5rem;
}

.theme_toggle_container:hover,
.dashboard_option_container:hover,
.alerts_option_container:hover,
.admin_settings_container:hover,
.logout_container:hover {
    cursor: pointer;
    background: var(--table_selected_item_background);
}

.profile_option_link { /*removes underline when hovering*/
    text-decoration: none !important;
}


.logout_container {
    position: absolute;
    bottom: 0;
}

.theme_toggle_icon,
.dashboard_option_icon,
.alerts_option_icon,
.admin_settings_icon,
.logout_option_icon {
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-left: auto;
    justify-content: flex-end;
}

.profile_and_settings_icons {
    color: var(--canvas_font) !important;
}


@media only screen and (max-width: 576px) {
    .profile_settings_modal .modal-content {
        margin-top: 1.5rem;
    }
}
    
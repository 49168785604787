


.default_currency_div {
  margin-top: 1rem;
  max-width: 270px;
}

.default_currency_label {
  margin-left: 1rem;
}

.settings_taxes_currency_form_container {
  margin-top: calc(var(--actionbar-height) + 1rem);
}
.currency_taxes_tax_rate_div {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* .default_currency_label,
.tax_rate_label,
.currency_used_label{
font-size: 1.25rem;
} */

.currency-options {
  display: flex;
  margin-top: .5rem;
}

.tax-rate {
  max-width: 300px;
}

@media only screen and (max-width:1300px) {
  .settings_taxes_currency_form_container {
    margin-top: var(--tablet-toolbar-height);
  }
}

@media only screen and (max-width: 576px) {
  .settings_taxes_currency_form_container {
    margin-top: var(--actionbar-height);
  }
}
.inventory-dash-help-container {
    position: fixed;
    background: var(--canvas_background);
    color: var(--canvas_font);
    height: 100%;
    width: 100%;
    max-width: 600px;
    z-index: 9999;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    border: 1px solid var(--actionbar_background);
    overflow: hidden;
}

.inventory-dash-help-container  iframe {
    height: 100%;
    width: 100%;
    position: relative;
    border: none;
}

.inventory-dash-help-container.open {
    transform: translateX(0);
}

.inventory-dash-help-card {
    height: 100%;
}

.inventory-dash-help-card-header {
    margin: 0 !important;
    left: 0 !important;
    height: 2.7rem !important;
    background: var(--actionbar_background) !important;
    display: flex;
    border-radius: 0;
    flex-direction: column;
    border: none !important;
}

.inventory-dash-help-card-header button, 
.inventory-dash-help-card-header button:hover {
    background: transparent;
    border: transparent;
    float: right;
    margin-right: 1rem;
    position: absolute;
    top:0;
    right: 1rem;
    padding: 0;
    color: var(--actionbar_font);
}

.inventory-dash-help-card-title {
    margin: auto 0 auto .5rem;
    color: var(--actionbar_font);
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.card-title {
    margin: 0;
    height: 100%;
    width: 100%;
}

.inventory-dash-help-card-body {
    padding: 0;
    overflow: hidden;
    overflow-x: hidden;
    border: none !important;
}

.inventory-dash-help-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 9998;
}

@media only screen and (max-width: 600px) {
    .inventory-dash-help-container {
    width: 100vw;
    }
}



.inventory-dash-help-content {
    width: 100%;
    height: 100%;
}
.purchaseorder_itemedit_local_backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, .3);
}
#itemPurchaseOrderForm {
  background: var(--canvas_background);
  height: 100%;
}
.purchaseorder_modal_window_container {
  height: calc(100%);
  display: flex !important;
  flex-direction: column;
}
.purchaseorder_add_item_btn {
  display: flex;
  align-items: center;
}
.purchaseorder_summary {
  padding: 0 .5rem;
 display: flex;
 background: var(--table_column_header_background);
 min-height: 5.75rem;
 max-height: 5.75rem;
 margin-top: auto;
}
.purchaseorder_grid_window {
  background: var(--canvas_background);
}
.purchaseorder_top_row {
  display: grid;
  grid-template-rows: 5rem 2rem;
  gap: .5rem;
  border-bottom: none !important;
  padding: 0;
  background: var(--canvas_background);
}
.top_row_field_container {
  display: grid;
  padding: .5rem 1rem 0 1rem;
  grid-template-columns: 1fr 1fr 2fr;
  gap: .5rem;
}
.purchaseorder_card_header {
  margin: 0 !important;
  padding: 0 1rem;
  left: 0 !important;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.purchaseorder {
  overflow-y: hidden;
}
.purchaseorder_items  {
  padding: 0;
  overflow-y: hidden;
}
.purchaseorder_summary {
  display: flex;
  padding: .25rem .5rem 0 .5rem;
  background: var(--table_column_header_background);
  min-height: 5.75rem;
  max-height: 5.75rem;
  margin-top: auto;
}

div.purchaseorder_total, div.purchaseorder_subtotal{
  display: flex;
  flex-direction: column
}

.calcuatedField {
  margin-top: .5rem;
}

.purchaseorder_tax span, .purchaseorder_shipping span {
  position: absolute;
  top: 2.45rem;
  left: 1.2rem;
}

@media only screen and (max-width: 576px) {

  #itemPurchaseOrderForm {
    position: absolute;
    background: var(--table_column_header_background);
  }
   .purchaseorder_summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: .5rem;
    padding: .5rem;
  }
  .purchaseorder_subtotal,
  .purchaseorder_shipping, 
  .purchaseorder_tax,
  .purchaseorder_total {
    margin: 0;
    padding: .25rem;
    min-width: 100%;
  }
  .purchaseorder_summary input {
    padding: .25rem;
  }

  .purchaseorder_top_row .top_row_field_container {
    gap: .25rem;
    padding: .25rem !important;
  }
  .purchaseorder_itemedit_local_backdrop {
    background: transparent;
  }
}

@media only screen and (max-height: 576px) {
  .purchaseorder_grid_window {
    min-height: 90vh;
    max-height: 90vh;
  }
}

/******************************
PRINT VIEW
******************************/
.printViewModal {
  min-height: 1500px !important;
  background: white !important;
  border: none;
  left: -3px;
}

.printViewModal .actionbar_modal_header, 
.printViewModal .actionbar_page_title_only {
  background: #a0a0a0;
  color: white;
  padding-left: 1rem;
}


.printView_purchaseorder_modal_window_container{
  background: white !important;
  color: black !important;
}

.printViewVendor .form-group, 
.printViewModal .form-control {
  background: white;
  display: flex;
  width: 100%;
}

.printViewModal .top_row_field_container {
  display: grid;
  grid-template-columns: 1fr;
}

.printViewModal .purchaseorder_top_row, .printView div.card-body, .printView_purchaseorder_items > .card-body, .printView .inventorydash_custom_modal_body, .printView .purchaseorder_items, .printView_purchaseorder_items   {
  background: white !important;
  color: rgb(34, 34, 34);
}

.printView_inventorydash_custom_modal_footer {
  margin-top: auto;
  margin-bottom: 0;
  position: fixed;
  bottom: 0;
  min-width: 1150px;
}

.printView_inventorydash_custom_modal_footer .purchaseorder_summary, .printView_inventorydash_custom_modal_footer .purchaseorder_summary label, .printView_inventorydash_custom_modal_footer .purchaseorder_summary .form-control {
  background: rgb(219, 219, 219);
  color: rgb(87, 87, 87);
  border: none;
}
.login_field_div, .register_form, .forgot_password_form {
    
    max-width: 800px;
    padding-bottom: 1rem;
  
}

.forgot_password {
    margin: auto;
    margin-top: 1rem;
}



.forgot_password {
    color: rgb(99, 102, 107);
}


.register {
    margin-left: 2rem
}

.btn-primary {
    background: var(--button_small_background);
    color: var(--button_small_font);
    border: 1px solid var(--button_small_border);
}

.btn-primary:hover,
.btn-primary:focus
.btn-primary:active {
    background: var(--button_small_hover_background);
    color: var(--button_small_hover_font);
    border: 1px solid var(--button_small_hover_border);
}

.forgot_password, .register_acct {
    margin-top: 1rem;;
}

 .form-row_register {
     min-width: 100%;
     margin-bottom: .5rem;
     padding: 0;
     margin: 0;
 }
div
 .form-group_register.col {
     margin: 1rem 0;
     padding: 0;
     min-width: 100%;
 }
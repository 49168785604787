

.unit_of_measure_check_div .form-check-label {
    margin: 0;
    padding: 1.1rem 0 0 0;
    font-size: 1em;
}


label.uom-form-label.form-label {
    margin: 0 0 .25rem 0;
    padding: 0 0 0 1rem;
    background: var(--table_column_header_background);
    min-width: 1000px;
    font-size: 1.25rem;
    text-emphasis: bold;
    color: var(--table_column_header_font);
    border-top: 1px solid var(--table_column_header_border);
}

.unit_of_measure_check_div {
    min-width: 100%;
    padding-left: 2rem;
    margin-right: 2rem;
    margin-top: 0;
    padding-top: 0;
}


div.unit_of_measure_col.col {
    padding: 0;
}

.uom_label_div {
    min-width: 1000px;
}
.unit_of_measure_col {
    min-width: 100%;
    padding-bottom: 1rem;
    margin: 0 0 0 .5rem;
}


.check-divs input.form-check-input {
    margin-top:1.5rem;
    margin-right: 0rem;
    margin-bottom: 0rem;
    

    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  
}


.uom_label_div {
    min-width: 100%;
    display: block;


}

@media only screen and (max-width: 576px) {
    .unit_of_measure_section {
        padding-bottom: 3rem;
    }

    .unitofmeasure_page_container {
        min-height: calc(100% - 5.5rem);
        max-height: calc(100% - 5.5rem);
    }
}
.item_modal_container {
    display: flex;
    flex-direction: row;
    min-height: 100%;
}

.item-form-container {
    /* line-height: 1.0rem; */
    /* display: flex;
    flex-direction: column; */
   width: 100%;
   display: flex;
   flex-direction: column;
}
.right-align-btn {
    margin-top: .5rem;
    height: 2rem;
    /* line-height: 1.0rem; */
    
}

.item_label_col {
    margin-bottom: 1.25rem;
}

.item-card {
    background: transparent !important;
    border: none !important;
}

.btn-container-items {
    padding-bottom: .5rem;
    
}


.item_summary {
   margin-top: auto;
    /* justify-self: flex-end; */
  
}


html {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;   */
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






/********************/
/*PC SCREEN*/
@media (min-width: 1000px) {
  
  main {
    margin-top: 0px;
  }

  .setting-title {
  
    text-align: center;
  }

}
.salesorder_itemedit_local_backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, .3);
}

.salesorder_modal_window_container {
  height: calc(100%);
  display: flex !important;
  flex-direction: column;
}

#itemSalesOrderForm {
 height: 100%;
  background: var(--canvas_background);
  
  /*prevents form from expanding beyond the edge of the modal and cutting off the border of the modal*/
}
.salesorder_add_item_btn {
  display: flex;
  align-items: center;
}

div.salesorder_total, div.salesorder_subtotal{
  display: flex;
  flex-direction: column
}

.calcuatedField {
  margin-top: .5rem;
}

@media only screen and (max-width: 576px) {
  #itemSalesOrderForm, #itemSalesOrderForm > div {
    /* margin-bottom: 0; */
    /* max-height: 80vh;
    min-height: 80vh; */
    /* max-height: 70vh; */
    /* position: absolute; */
    height: 100%;
    /* height: calc(95vh - .563rem); */
    /* background: var(--table_column_header_background); */

    width: calc(100vw - .438rem);
    /* border: 3px solid inherit; */
  }

  .salesorder_top_row .top_row_field_container {
    grid-gap: .25rem;
    padding: .25rem
  }

  .salesorder_modal_window_container {
    /* height: calc(95vh - 35rem); */
  }

  .salesorder_summary {
    padding: .25rem !important;
  }

  .salesorder_summary div {
    padding: 0;
    margin: 0 .1rem;
  }
}

.salesorder_top_row {
  display: grid;
  grid-template-rows: 5rem 2rem;
  gap: 0.5rem;
  border-bottom: none !important;
  padding: 0;
  background: var(--canvas_background);
}
.top_row_field_container {
  display: grid;
  padding: 0.5rem 1rem 0 1rem;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 0.5rem;
}
.salesorder_card_header {
  margin: 0 !important;
  padding: 0 1rem;
  left: 0 !important;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.salesorder {
  overflow-y: auto;
  min-height: 100%;
  /* height: calc(95vh - 22rem); */
}
.salesorder_items {
  padding: 0;
  overflow-y: hidden;
}

.salesorder_tax span, .salesorder_shipping span {
  position: absolute;
  top: 2.45rem;
  left: 1.2rem;
  color: var(--table_column_header_font);
}
.salesorder_summary {
  display: flex;
  padding: .25rem .5rem 0 .5rem;
  background: var(--table_column_header_background);
  min-height: 5.75rem;
  max-height: 5.75rem;
  margin-top: auto;

}
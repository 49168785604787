.search_links_header  {
    min-height: 4rem;
    display: flex;
    align-items: center;
    padding: .5rem !important;
    background: var(--actionbar_background);
    min-width: 100%;
}

.search_modal_header .nav-tabs {
    max-width: calc(100% - 1rem);
    padding: 1rem 1rem 0 1rem;
    border-color: transparent;
}

.nav-link.search_list_item {
    color: var(--actionbar_font);
    height: 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 7px;
    margin-bottom: .5rem;
}

.search_links:hover {
    background: none !important;
    border-radius: 0;
}

.nav-link.search_list_item:hover {
    background: none !important;
    border-top: 1px solid var(--actionbar_background) !important;
    border-left: 1px solid var(--actionbar_background) !important;
    border-right: 1px solid var(--actionbar_background) !important;
    outline:none !important;
    border-radius: 0;
}

.search_list_item.nav-link.active {
    color: var(--navbar_background) !important;
}

.search_list_item.nav-link.active:hover {
    background: var(--canvas_background) !important;
    border: 1px solid var(--canvas_background) !important;
    border-radius: 7px;
}

@media only screen and (max-width: 576px) {
    .search_links_header {
        padding: 0 .5rem;
    }

    .search_list_item.nav-link {
        height: 1.5rem;
        display: flex;
        align-items: center;
        min-width: 50px;
    }

    .search_list_item.nav-link.active {
        height: 1.5rem;
        display: flex;
        align-items: center;
        min-width: 50px;
        padding: .25rem;
    }

    .search_links_header button.close {
        position: absolute;
        right: 0;
        top: 0;
    }
}



/*properties that prevent a line from appearing on the modal header's right side*/
.modal_header_with_fields {
  min-width: calc(100% + 3px);
  position: relative;
  padding-right: 2px;
}

.purchaseorder_top_row {
  max-width: calc(100% - 2px);
}

@media only screen and (max-width: 1300px) {
  .purchaseorder_page_container {
    overflow: hidden;
    height: calc(100% - 2.7rem) ;
  }
}

 

@media only screen and (max-height: 1000px) {
  .modal_body_with_fields_header_and_fields_footer {
    max-height: 80vh;
    min-height: 50vh;
  }
}

@media only screen and (max-width: 576px) {
  .purchaseorder_page_container {
    overflow: hidden;
    height: calc(100% - 5.5rem);
  }
  .modal_body_with_fields_header_and_fields_footer {
    /* height: calc(100% + 15rem); */
    /* max-height: 0; */
    /* min-height: calc(100% - 10rem); */
    /* max-height: 80vh;
    min-height: 50vh; */
    /* min-height: calc(95vh - 25rem); */
  }

  .modal_height_spacer {
    max-width: calc(100vw - 4px);
  }

}
@media only screen and (max-height: 575px) {
  .modal_body_with_fields_header_and_fields_footer {
    height: auto;
    min-height: calc(70vh - 15.5rem);

  }
}
























/* .purchaseorder_price {
  min-width: 150px;
} */

/* .purchaseorder_number_autoComplete {
  position: relative;
  left: 100%;
  bottom: 45%;
  margin-left: 0.25rem;
} */

/* .modal-footer .purchaseorder_modal_footer {
  border-top: 1px solid transparent !important;
  background-color: burlywood !important;
  margin: 0 !important;

} */
/* .purchaseorder_modal_footer {
  border: transparent !important;
} */

  /* .grid_purchaseorder_lineitem_icon_check {
      grid-column-start: 1;
      grid-column-end: 2;
  } */

/* .grid_purchaseorder_row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr;
  column-gap: 0.5rem; */

  /* For multiple properties within the row, double sized, more info */
  /* grid-template-rows: repeat(2, 1fr);
  border: 1px solid var(--table_border);
  margin-right: 1rem;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
} */

/* .grid_purchaseorder_lineitem_icon_check {
  margin-left: 0rem;
  margin-right: 0.25rem;
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  color: var(--canvas_font);
  max-width: 3rem;
  opacity: 0.5;
} */

/* .grid_purchaseorder_price {
  grid-column-start: 4;
  grid-column-end: 6;
}
.grid_purchaseorder_name {
  grid-column-start: 1;
  grid-column-end: 3;
}
.purchaseorder_lineitem_secondrow_item {
  grid-column-start: 1;
  grid-column-end: 1;
}
.purchaseorder_lineitem_secondrow_item2 {
  grid-column-start: 3;
  grid-column-end: 5;
} */

/* @media only screen and (max-width: 576px) {
  .purchaseorder_top_row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1rem;
  }

  .purchaseorder_items {
    row-gap: 0.5rem;
  }

  .grid_purchaseorder_row {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0.5rem;
  }

  .grid_purchaseorder_lineitem_icon_check {
    margin-left: 0rem;
    margin-right: 0.25rem;
    margin-top: 0.6rem;
    display: flex;
    align-items: center;
    color: var(--canvas_font);
    max-width: 3rem;

    opacity: .5;
}

.purchase_order_rec_input {
    overflow-x: hidden;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--canvas_background);
    border-radius: 5px;
    max-width: 26rem;
    max-height: 16rem;
    z-index: 101;
}

.purchase-order-card-header {
    display: grid;
    grid-template-rows: auto 1fr auto;

    opacity: 0.5;
  }

  .grid_purchaseorder_price {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .grid_purchaseorder_name {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .purchaseorder_lineitem_secondrow_item {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .purchaseorder_lineitem_secondrow_item2 {
    grid-column-start: 1;
    grid-column-end: 1;
  }
} */

/* ############################################################################## */

/* @media only screen and (max-width: 1000px) {
  .purchaseorder_row {
    border: 1px solid rgba(128, 128, 128, 0.234);
    width: calc(100% + 0.5rem);
    padding: 0.5rem 0;

     /* .grid_purchaseorder_row {
    display: grid;
    height: 200px;
    border: 1px solid var(--table_column_header_background);
    grid-template-columns: repeat(12, 1fr) !important;
    grid-column-gap: .5rem;
    padding: .5rem;
    }

    .grid_purchaseorder_lineitem_icon_check {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .grid_purchaseorder_name {
        grid-column-start: 2;
        grid-column-end: -1
    }

    .grid_purchaseorder_qty {
        grid-column-start: 1;
        grid-column-end: 6;
    }

    .grid_purchaseorder_price {
        grid-column-start: 6;
        grid-column-end: 12;
    }

    .purchaseorder_lineitem_received{
        grid-column-end: -1;
    }
  }
} */

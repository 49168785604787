.finalizeReturn {
  display: flex;
  flex-direction: column;
}

.finalizeReturn .itemInput {
  width: 100%;
  margin-top: -.25rem;
}

.finalizeReturn span {
  padding-left: .5rem;
}

.finalizeReturn  .returnCheckbox,
.finalizeReturn .masterCheckbox  {
  max-width: 2rem;
}

.finalizeReturn .masterCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finalizeReturn div.masterCheckbox.col {
  padding: 0;
}

.finalizeReturn .header div.col {
  padding: .25rem;
  margin: auto;
}
.finalizeReturn .header .col {
  font-weight: bold;
  padding: .5rem .25rem;  
}



.returnItemRow {
  border-bottom: 1px solid var(--table_column_header_background);
  margin: 0;
}

.returnItemRow .col {
  padding: .25rem;
  align-items: center;
}

.returnItemRow .returnItemData {
  padding-top: .5rem;
}

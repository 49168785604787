.tags-input-container{
    /* border: 1px solid var(--field_border); */
    background: var(--field_background);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    min-height: 38px;
    border-radius: 5px;
}

.expanded_tag_container{
 max-height: 100px;
 overflow-y: auto;
}

.tag-item{
    background-color: rgb(230, 230, 230);
    display: inline-block;
    padding: .1rem;
    border-radius: 5px;
    margin: .1rem .25rem;
    border: 1px solid rgb(96, 96, 96);
}
.tag-item .close{
    height: 10px;
    width: 10px;
    color: #000000;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.tags-input{
    flex-grow: 1;
    padding: 6px 12px;
    border: 1px solid var(--field_border);
    outline: none;
    background: var(--field_background);
    min-height: 38pxrem;
    border-radius: 5px;
    ;
}

.tags-input:focus {
    background: var(--field_select_active_background);
    color: var(--field_select_active_font);
    padding-left: .25rem;
}

.expanded_tag_container > ul, .expanded_tag_container ul li, .expanded_tag_container span {
    list-style-type: none;
    padding: 0;
    margin-left: .4rem;
}
.div-col1 {
    border: 1px solid red;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
}

.row1 {
    background: black;
    min-width: 100%;
    min-height: 3rem;
    max-height: 3rem;
   display: flex;
   flex-wrap: nowrap;   
}

.col1 {
    background: burlywood;
    min-width: max-content;
    max-width: 100px;
}

.page_container {
  /* border: 1px solid red; */
}

.page_container > div {
  /* border: 1px solid blue; */
}

/* .page_container > div > div {
  border: 1px solid yellowgreen;
  height: 100%;
} */

.table-container {
  /* border: 1px solid red; */
}

.table-container > div {
  /* border: 1px solid pink; */
  height: 100%;
}

.table-container > div > div {
  height: 100%;
}
.filterModal {
    padding: .5rem;
}

.filterTitle {
    display: grid;
    grid-template-columns: 7rem 10rem 1fr;
    max-width: 100%;
    gap: .5rem;
}

.numberRange {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 7rem 1fr 1fr;
    max-width: 100%;
    gap: .5rem;
    
}

.filterModal p {
    margin-top: 2.25rem;
    font-weight: 600;
}

.labelFilter {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 7rem 1fr;
    max-width: 100%;
    gap: .5rem;
}

.filterAppliedIcon {
    background: rgb(231, 231, 26);
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    margin-top: -1rem;
    color: var(--table_column_header_font);
    padding-left: .3rem;
    user-select: none;
}

/* Group Filter */
.itemGroupFilter .form-group{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 7rem 1fr;
    gap: .5rem;
    font-weight: 600;
}


/* type filters */
.itemTypeFilter .form-group{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 7rem 1fr;
    gap: .5rem;
    font-weight: 600;
}

/* vendor filters */
.itemVendorFilter .form-group {
    display: grid;
    grid-template-columns: 7rem 1fr;
    gap: .5rem;
    font-weight: 600;
}

/* location filter */
.itemLocationFilter .form-group {
    display: grid;
    grid-template-columns: 7rem 1fr;
    gap: .5rem;
    font-weight: 600;
}

@media only screen and (max-width: 400px) {
    .filterTitle {
        display: grid;
        grid-template-columns: 7rem  1fr;
        grid-template-rows: 1fr 1fr;
        max-width: 100%;
        gap: .5rem;
    }

    .filterTitle .form-group {
    grid-column-start: 2;
    }
    }
  


.tree-control-selector-backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(128, 128, 128, .25);
    z-index: 5;
    top: 0;
    left: 0;
}

.tree-control-selector-outer-container {
    position: absolute;
    overflow: hidden;
    border: 1px solid var(--actionbar_background) !important;
    border-radius: 5px;
    z-index: 12;
    max-height: 22rem;
    min-height: 22rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: content;
    background: var(--canvas_background);
    color: var(--canvas_font);
    width: 100%;
    /* top: 32px; */
}

.tree-control-selector-inner-container {
    display:flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
}

.tree-control-selector {
    border-top: none;
    overflow: auto;
    border-radius: 0 0 7px 7px;

}

.tree-control-select-list-item {
    border: none;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.tree-control-select-list-spacer {
    margin-left: .75rem;
}

.tree-control-selector-button-section {
    border-bottom: 1px solid var(--table_border);
    padding-top: .2rem;
    padding-bottom: .2rem;
}

.location_selection_btn, .location_selection_btn_close {
    background: transparent !important;
    outline: transparent !important;
    border: transparent !important;
    padding: 0;
    width: 50px;
    height: 2rem;
}

 .location_selection_btn_close {
    display: flex;
    justify-content: flex-end;
    width: 1.75rem;
}


.tree-control-selector-button-section > .btn {
    bottom: 2;
    float: right;
}

.tree-control-selector-selection-text {
    display: inline;
    padding-left: .5rem;

}

/* @media only screen and (max-height: 850px) {
    .tree-control-selector-outer-container {
        min-height: 50px;
        height: 30vh;
        position: fixed;
        z-index: 250;
    }
} */

/* @media only screen and (max-width: 768px) {
    .tree-control-selector-outer-container {
        position: fixed;
        z-index: 99;
        /* top: 0;
        max-width: 70rem;
        width: calc(90% - 3rem);
    }
} */



@media only screen and (max-height: 830px) {
    .tree-control-selector-outer-container {
        min-height: 2rem;
        max-height: 15rem;
    }
}

@media only screen and (max-height: 530px) {
    .tree-control-selector-outer-container {
        max-height: 7.5rem;
    }
}   

@media only screen and (max-width: 576px) {
    .tree-control-selector-outer-container {
        position: fixed;
        min-height: calc(100vh - 9.5rem);
        width: 100vw;
        bottom: 0; left: 0;
        z-index: 999;
    }
}
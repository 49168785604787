.settings_container {
  width: 100%;
  margin: calc(var(--actionbar-height) + 1rem) 0;
  max-width: 900px;
  
}

.settings_btn_container {
  width: 100%;
  /* margin-left: auto; */
  max-width: 900px;
}

.btn_large {
  height: 7rem;
  width: 7rem;
  margin: 20px;
  flex-grow: 5;
}

div
.settings-button-row {
  display: flex;
  justify-content: space-around;
  max-width: 600px;
}

button.btn_large {
  background: var(--button_large_background);
  border-radius: 1rem;
  border-color: var(--button_large_border);
  color: var(--button_large_icon);
}


.button_icon_font_color:hover, .button_icon_font_color:focus {
  color: var(--button_large_hover_icon) !important;
}

.btn_large:hover, .btn_large:focus {
  background-color: var(--button_large_hover_background) !important;
  border: 1px solid var(--button_large_hover_border) !important;
  color: var(--button_large_hover_font) !important;
  outline: none !important;
  box-shadow: none !important;
}


/*spacers keep icons and paragraphs aligned within the button*/
div .spacer {
  height: 1rem;
}

div .small-spacer {
  height: 0.6rem;
}

.top-spacer {
  height: 0.5rem;
}

.tiny-spacer {
  height: 0.3rem;
}

.back-button path {
  border: none;
  padding: 0rem;
  color: var(--button_large_icon);
}

@media (max-width: 576px) {
  .btn-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .settings_container {
    display: block;
    height: calc(100% - var(--actionbar-height));
}

  .setting-btn-col {
    display: flex;
    min-width: 50%;
    padding:0;
  }

  .save_cancel_buttons {
    display: flex;
    flex-direction: column-reverse;
    min-width: max-content;
    min-height: 6rem;
    justify-content: flex-end;
  }

  button.settings_cancel_button,
  button.settings_save_button {
   max-width: 5rem;
    align-self: flex-end;
  }

  button.settings_save_button {
    margin-bottom: .5rem;
  }
}


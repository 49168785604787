.disable-select {
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   height:2.2rem;
   padding: 0 .5rem !important;
}

.table td, .table th {
    border: none;
}

.table thead th { /*removes border from table header*/
    border: none !important;
}


.display_table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    background: var(--canvas_background);
    color: var(--canvas_font);
    margin: 0;
    min-width: 350px !important;
    max-width: 100vw;
}

th.disable-select {
    padding-top: .25rem !important; /*helps to center the column header font vertically*/
}

td.display_table-cell {  /*padding in each table row*/
    cursor: default;
    padding: .5rem;
    font-size: 1em;
  }

tr.display_table-row { /*border around*/
    border: 1px dotted var(--table_border) !important;
  }

/*removes additional scrollbar*/
[currentselecteditem], div.table-container > div {
    overflow: hidden !important;
}

.table-container  {
    width: 100%;
    height: 100%;
}

.table-container > div > div {
    overflow: hidden;
}

.table {
    border: none !important;
}

/*table column header*/
.display_table_th {
    position: sticky;
    position: -webkit-sticky;
    z-index: 25;
    background: var(--table_column_header_background);
    color: var(--table_column_header_font);
    display: flex;
    align-items: center;
}


td.display_table-cell.text-right::before,
td.display_table-cell::before {
    color: var(--table_column_header_font);
}

/* .page-container {
    height: 100%;
} */

.display_table-cell {
    /* overflow: hidden !important; */
    white-space: nowrap;
}

.display_table-cell div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.display_table_current_selection_row {
    /* background:  rgba(0,0,0,.075); */
    background: var(--table_selected_item_background);
    color: var(--table_selected_item_font);
}

@media only screen and (max-width: 760px) {
    .table-container, .table-container div {
      min-height: 100%;
    }

  }

@media
only screen and (max-width: 576px) {
    .table-container {
        height: calc(100% - 2.5rem) !important;
        padding: var(--actionbar-height) 0 ;
        margin-bottom: 2.5rem;
    }
}

/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    .table-container {
        height: calc(100% + 0rem);
        padding: 0
    }

    .table-container > div {
        min-height: 100%;
        overflow: hidden !important
    }

    .display_table {
        border-collapse: separate;
        padding: 0;
        margin: 0;
    }

	.display_table-row {
        border-radius: .25rem;
        float: left;
        width: 100%;
        display: table-row !important ;
        margin-top: 0;
    }

    .display_table-cell {
        display: block !important;
        border: none !important;
        position: relative;
        width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .display_table td {
        padding: .5rem .75rem
    }

    .display_table-cell:before {
        position: relative;
        content: attr(th-data)": ";
        color: var(--table_column_header_mobile_font) !important; /* changes color of column header text when on cards for mobile screens */
        font-weight: bold;
    }

    .display_table-cell div {
        display: inline;
		border: none !important;
        position: relative;
    }

    .display_table-cell:last-child {
        border-bottom: 1px solid #ccc;
    }

    /* .display_table + div { */
        /* height: 100% !important;  */
        /*fixes issue where the table did not take up the entire screen height*/
    /* } */
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    align-items: center;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button__linetop,
.toggle-button__linemid,
.toggle-button__linebot {
    width: 28px;
    height: 3px;
    background: var(--navbar_menu_icon);
    transform: translateY() ;
    transform: skewX();
    transition: .2s;
    align-items: center;;
    border-radius: 25%;
}

.toggle-button__linetop.open {
    transform: translateY(3px) rotate(-215deg);
    transition: .4s;
}

.toggle-button__linemid.open {
    transform: skewY(45deg);
    transform: rotateY(270deg);
    transition: .1s;
}


.toggle-button__linebot.open {
    transform: translateY(-3px) rotate(-145deg);    
    transition: .4s;
}


@media only screen and (min-width: 576px) {

    .toggle-button__linetop,
    .toggle-button__linemid,
    .toggle-button__linebot  {
        display: none;
    }

    .toggle-button {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
}






.tree-control-outer-container {
    min-height: calc(100% - .35rem);
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
}

.item_location_page_container .tree-control-outer-container {
        padding-top: 0;
        border-top: 1px solid rgb(170, 169, 169);
}

.add_sub_location {
    color: var(--canvas_font);
    margin-left: .25rem;;
}

.tree-control-add-item:hover {
    cursor: pointer;
}

.tree-control-expand-box:hover {
    cursor: pointer;
}

.item_location_page_container .list-group {
    /* overflow-y: auto; */
    max-height: calc(100vh - 10rem);

}

@media only screen and (max-width: 1300px) {
    .tree-control-outer-container {
        /* min-height: calc(100% - .25rem); */
    }

   

    .item_location_page_container {
        padding-bottom: 1rem;
    }

    .item_location_page_container .list-group {
        overflow-y: auto;
        max-height: calc(100vh - 11.5rem);
    }

}

@media only screen and (max-width: 576px) {
    .small_modal_container {
        width: 99.5%;
        left: 1%;
        margin: 0;
    }

    .item_location_page_container .list-group {
        overflow-y: auto;
        max-height: calc(100vh - 12.5rem);
    }

    .no_border {
        border: none !important;
    }

    .tree-control-outer-container {
        padding-bottom: 1.5rem;
    }
}
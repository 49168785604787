
/* .search_modal_search_header {
    min-width: 100%;
} */

.search_modal_search {
    padding: .5rem;
    display: flex;
    height: 3.5em;
    position: relative;
    /* border: 1px solid blue; */
    /* background: var(--table_column_header_background); */
}

.search_input:focus, .search_input {
    height: 2.5em;
    /* border: 1px solid var(--field_border) !important; */
}

/*icons*/
.barcode_scanner_icon {
    margin: 0 0 0 .5rem;
}

.clear_search_icon {
    position: absolute;
    right: .5rem;
    z-index: 99;
    align-self: center;
    color: rgb(182, 182, 180);
    cursor: pointer;
}

.search_modal_search_icon {
    
    border: 1px solid var(--field_border) !important;
    padding: .5rem;
    height: 2.5em;
    width: 3em;
    border-radius: 0 20px 20px 0;
    background: var(--navbar_background);
    color: var(--navbar_font);
    cursor: pointer;
}

@media screen only and (max-width: 576px) {
    .search_modal_search_header {
        margin-bottom: 1rem;
    }
}
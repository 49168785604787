.sales-order-line-item-outer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* transition: 3s all ease-in-out; */
}

.sales-order-view-only-line-item-outer-container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--table_column_header_background);
  cursor: zoom-in;
  background: var(--canvas_background);
}

.grid_salesorder_row {
  margin: 1rem 0.5rem 0 0.5rem;
  min-width: 95%;
  grid-template-columns: 3fr 1fr 1fr;
  display: grid;
  grid-template-rows: 1fr;
  gap: 0.5rem;
}

.sales-order-line-item-view-detail {
  display: grid;
  grid-template-columns: 3fr repeat(5, 1fr);
  grid-auto-flow: column;
  margin-bottom: 0;
  grid-column-gap: 0.25rem;
  padding: 0.5rem;
  overflow: hidden;
  width: 100%;
  height: fit-content;
}

/* Allow Item to Expand With This Control */
.sales-order-line-item-left-align {
  text-align: left !important;
}

.sales-order-line-item-view-expander {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-right: 1.5rem;
  cursor: auto;
}

.sales-order-line-item-right-align {
  text-align: right;
}

.sales-order-line-item-center-align {
  text-align: center;
}

.sales-order-line-item-detail-header {
  font-weight: bolder;
  text-align: center;
}

.grid_salesorder_price input.form-control {
  width: calc(100% - 2.5rem);
}

.salesorderline_item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.salesorder_items {
  background: var(--canvas_background);
  border-color: transparent;
}

.salesorder_add_item_btn,
.salesorder_add_item_btn:active,
.salesorder_add_item_btn:focus {
  background: transparent !important;
  border: none !important;
  margin-left: 0.25rem;
  color: var(--canvas_font) !important;
}

.salesorder_line_expandLess {
  position: relative;
  bottom: 0rem;
  right: -0.5rem;
  cursor: pointer;
}

.salesorder_lineitem_secondrow_grid {
  padding: 0 0.5rem;
  display: grid;
  /* grid-template-rows: 4.5rem auto; */
  column-gap: 0;
  column-gap: 1rem;
  row-gap: 0.25rem;
  grid-template-columns: 12.5rem 1fr;
}

.salesorder_lineitem_thirdrow_grid {
  padding: 0 0.5rem;
  display: grid;
  column-gap: 0;
  column-gap: 1rem;
  row-gap: 0.25rem;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}

.salesorder_lineitem_secondrow_grid > div {
  margin: 0 !important;
  padding: 0.35rem 0 0 0;
}

.salesorder_checkbox_component {
  padding: 0 !important;
  grid-column-start: 1;
  grid-column-end: 2;
}

.salesorder_datepicker_input_field {
  min-width: 50px !important;
  max-width: 100% !important;
}

.salesorder_expected_date {
  grid-column-start: 2;
  grid-column-end: 3;
}

.salesorder_note {
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: -1;
}

.salesorder_note .form-group textarea {
  min-height: 14rem;
}

.salesorder_lineitem_secondrow_grid .tracking{
  margin-top: 1rem;
}

.salesorder_lineitem_secondrow_grid .tracking .form-group{
  margin-bottom: 0;
}


.salesrorder_qty_recieved {
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 3;
}

.salesorder_lineitem_recieved_area {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  border-radius: 10px;
  background: var(--disabled_control_background_color);
}

.salesorder_lineitem_recieved_area.enabled {
  background: initial;
}


/*****************************************************
  *****************************************************/

/*button styles that override react default styling*/
/* .sales-order-line-item-view-expander-button:focus { */
/* display: block;
      background: var(--button_small_background) !important;
      color: var(--button_small_font) !important;
      border: 1px solid var(--button_small_border) !important;
      outline: none !important;
      box-shadow: none !important; 
  }*/

@media only screen and (max-width: 1000px) {
  .grid_salesorder_row {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 1fr 1fr;
    margin: 0;
  }

  .grid_salesorder_name {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .salesorder_lineitem_secondrow_grid {
    display: grid;
    grid-template-rows: 10rem 9rem;
    row-gap: .5rem;
  }

  .salesorder_checkbox_component {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .salesorder_expected_date {
    grid-column-start: 2;
    grid-column-end: -1;
  }

  .salesorder_note {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-column-end: -1;
  }

  .sales-order-line-item-outer-container {
    padding: .5rem;
  }
}

@media only screen and (max-width: 576px) {
  .sales-order-line-item-view-detail {
    height: 10rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-auto-flow: initial;
    grid-template-rows: repeat(2, 1fr);
  }

  .sales-order-line-item-view-expander {
    top: -2rem;
    height: 1.5rem;
  }

  .sales-order-line-item-outer-container .expanded {
    max-height: 5rem;

  }

  .salesorder_lineitem_secondrow_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 80px 30px auto; */
    column-gap: 0.5rem;
  }

  .salesorder_checkbox_component {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .salesorder_expected_date {
    grid-column-start: 2;
    grid-column-end: -1;
    min-width: 100px;
  }

  .salesorder_note {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-column-end: -1;
    padding-right: 1.5rem !important;
  }

  .datepicker-container {
    min-width: 100px;
  }

  .salesorder_datepicker_input_field {
    min-width: 100px;
  }

  @media only screen and (max-width: 450px) {
    .salesorder_lineitem_secondrow_grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 5rem 1fr;
      gap: 0.5rem;
    }

    .salesorder_checkbox_component {
      grid-column-start: 1;
      grid-column-end: -1;
    }

    .salesorder_expected_date {
      grid-column-start: 1;
      grid-column-end: -1;
      min-width: 100px;
      grid-row-start: 2;
    }

    .salesorder_note {
      grid-column-start: 1;
      grid-row-start: 3;
      grid-column-end: -1;
      /* grid-row-start: 4; */
    }
  }
}

.purchaseorder_line_options, .purchaseorder_line-options {
  position: absolute;
  right: 0;
  padding: 0;
  /* padding-right: .5rem; */
  margin-top: -.5rem;
}


.purchaseorder_options_overlay {
  width: 100vw;
  height: 100vh;
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lineItem_options_container {
  z-index: 999 !important;
  /* height: 9.5rem; */
  width: 12rem;
  background: var(--canvas_background);
  /* position: absolute;
  right: 1rem; */
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: repeat(5, 1fr); */
  align-items: center;
  border: 1px solid var(--navbar_background);
  padding: 0;
  position: fixed;
}

.lineitem_options_header {
  background: var(--table_column_header_background);
  color: var(--table_column_header_font);
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 7px 7px 0 0;
  padding: 0 .5rem;
}

.lineitem_options_header span {
  margin-left: auto;
  cursor: pointer;
  font-size: .8em;
  padding-bottom: .5rem;
}

.lineitem_options_header:hover {
  background: var(--table_column_header_background) !important;
  color: var(--table_column_header_font0) !important;
  cursor: default !important;
}

.lineitem_option_item, .lineitem_option_item_bottom  {
  padding: 0 .5rem;
  display: flex;
  justify-content: space-between;
  margin-top: .25rem;
}

.lineitem_option_item:hover, .lineitem_option_item_bottom:hover {
  cursor: pointer;
  background: var(--field_select_active_background);
  color: var(--field_select_active_font);
}

.lineitem_option_item_bottom:hover {
  border-radius: 0 0 7px 7px !important;
}
.formcontrol {
    display: flex;
    
    margin: 0 .5rem .5rem 0;
}

.formcontrol .col {
    display: flex;
    justify-content: flex-end;
}

.formcontrol  button, 
.formcontrol button:focus, 
.formcontrol button:active,
.formcontrol button svg {
    background: none !important;
    padding: 0;
    border: none !important;
    color: var(--button_small_icon);
    outline: none !important;   
    box-shadow: none !important;
    margin: 0 0 0 1rem;
}



.formcontrol button:hover svg {
    color: var(--button_small_hover_icon);
}
:root {
  --sidebar-width: 8.4rem;
  --toolbar-height: 4.5rem;
  --mobile-toolbar-height: 4.5rem;
  --tablet-toolbar-height: 5.5rem;
  --actionbar-height: 3rem;
  --tab_height: 3rem;
  --modal-large-height: 80vh;
  --modal_height: 85vh;
}

/********************************************
         TESTING SECTION
-------------------------------------------*/


/*test*/

/********************************************
        MAIN APP
 ------------------------------------------*/
.App {
  font-size: 16px;
  background-size: cover;
  overflow: hidden; /* THIS MAY BE A BAD THING MAKE SURE IT DOESN'T CAUSE ISSUES TODO */
}

.main {
  position: fixed;
  width: 100%;
  top: var(--toolbar-height);
  height: calc(100% - 4.5rem);
  padding: 0;
  background: var(--canvas_background);
}

.main-app-space {
  width: 100%;
  background: transparent;
  overflow-x: hidden;
  overflow-y: hidden;
  background: inherit;
  height: 100%;
}

.main-app-space.open {
  transition: transform 0.3s ease-in-out;
}

.form-group {
  /* Format that applies to entire application */
  margin-bottom: 0.25rem;
}

.alert {
  z-index: 9999;
}

.toast-alert {
  z-index: 9999;
  background: #fff;
}

.alerts-container {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 22rem;
}

@media only screen and (max-width: 1300px) {
  main.main {
    margin-top: 1rem;
    height: calc(100% - var(--tablet-toolbar-height));
  }
}

@media only screen and (max-width: 576px) {
  main.main {
    height: calc(100% - var(--toolbar-height));
    margin-top: 0;
  }
  .main-app-space.open {
    position: fixed;
    transform: translateX(0px);
    transition: transform 0.3s ease-in-out;
  }

  div.main-app-space {
    padding-top: 0;
    margin: 0;
    height: 100%;
  }
}

/******************************************
      PAGE CONTAINER   -first div in each page
-----------------------------------------*/
.page_container {
  display: block;
  height: calc(100% - var(--actionbar-height));
  /* margin: var(--actionbar-height) 0; */
  overflow: hidden !important;
  /* overflow-y: auto; */
}

.top_padding {
  padding-top: 4rem !important;
}

.page_container_padding {
  display: block;
  padding: 1rem;
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.loading {
  /*loading text*/
  font-size: 1.5em;
  margin-left: 0.5rem;
  color: var(--canvas_font);
  margin-top: 3rem;
}

@media only screen and (max-width: 576px) {
  .page_container {
    padding-bottom: 2.5rem;
    overflow: hidden;
    /* min-height: calc(100% - var(--actionbar-height)); */
    /* margin-top: calc(var(--actionbar-height)); */
  }

  /* .top_padding {
    padding-top: 3rem !important;
  } */
}

/***********************************************
        FORMS AND FIELDS
----------------------------------------------*/
div.form-group {
  margin-bottom: 1.25rem;
}

/*city, state, zip address fields*/
.city_state_zip {
  display: flex;
}

.city {
  min-width: 60%;
  max-width: 100%;
}

.state {
  max-width: 6rem;
  min-width: 6rem;
}

.zip {
  max-width: 250px;
  min-width: 150px;
}

button.dropdown-toggle.btn.btn-outline-secondary {
  /*drop down selectors throughout app*/
  background: var(--field_select_dropdown_background);
  border: 1px solid var(--field_border);
  color: var(--field_select_dropdown_font);
  z-index: -1;
}

.datepicker-container {
  min-width: 100%;
}

.react-datepicker-wrapper {
  min-width: 100%;
  max-width: 100%;
}

.react-datepicker__input-container {
  min-width: 100%;
}

.react-datepicker__input-container input {
  max-width: 100%;
}

textarea.form-control,   /*field, radio button, checkbox styles throughout*/
  input.datepicker_input_field,
  input.form-control,
  select.form-control {
  background: var(--field_background);
  color: var(--canvas_font);
  border: 1px solid var(--field_border);
  padding: 0.375rem 0.75rem;
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control:focus,
input.datepicker_input_field:focus,
select.form-control:focus,
input.form-control:focus,
textarea.form-control:active,
input.datepicker_input_field:active,
select.form-control:active,
input.form-control:active,
div.dropdown-menu.show,
div.react-datepicker {
  outline: none;
  border: 1px solid var(--field_select_active_border);
  background: var(--field_select_active_background);
  color: var(--field_select_active_font);
}

.form-control:disabled,
.form-control[readonly] {
  background: var(--button_small_background);
  color: var(--button_small_font) !important;
  cursor: not-allowed;
}
.form-control:disabled,
.form-control[readonly]:active {
  background: var(--button_small_background) !important;
  color: var(--button_small_font) !important;
}
.form-control:disabled,
.form-control[readonly]:focus {
  background: var(--field_background);
  color: var(--field_font);
}

.display_table {
}

.display_table + div {
  /*this allows the table content to shrink below 400px width*/
  max-width: 100%;
  overflow-x: hidden !important;
  /*REVIEW - changed this to overflow-y overlay to prevent taking up width of div*/
  overflow-y: overlay !important;
}


.table td {
  /*removes react default border*/
  border: none;
}

label {
  /*sets labels to canvas font color*/
  color: var(--field_label_font);
}

input.form-check-input {
  size: 50px;
}

.input-group-append {
  margin: 0;
}

.list-group {
  /*any list group - includes ColumnSelector*/
  border-radius: 0;
}

.form-control:focus {
  /*removes default react glow around input fields*/
  border-color: none;
  box-shadow: none;
}

div.react-datepicker-popper {
  /*date picker set to display in front of any other element*/
  z-index: 9999 !important;
}

/* Add this class to elements to prevent them being selected and highlighted
  good for labels/headers/etc */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



@media only screen and (max-width: 576px) {
  .city {
    min-width: 100%;
  }

  .zip {
    max-width: 100%;
  }

}

/*********************************************
     MODALS
--------------------------------------------
dialogClassName={"custom_modal_dialog"] is used on most <Modal> components
  if modal is small or medium, add "modal_small" or "modal_med" to the dialogClassName
  if a custom modal is needed, you can create a unique dialogClassName

bsPrefix={"custom_modal_header"} is used on most <Modal.Header> components
bsPrefix={"custom_modal_body"} is used on most <Modal.Body> components
  if modal contains a tab nav in the header, add "modal_body_with_tab_header" in addition to "custom_modal_body" to the bsPrefix for <Modal.Body>
  if modal contains a row of fields in the header and a footer of fields, add "modal_body_with_fields_header_and_fields_footer" in addition to "custom_modal_body to the bsPrefix for the <Modal.Body>

bsPrefix={"custom_modal_footer"} is used on most <Modal.Footer> components
*/


.inventorydash_custom_modal_dialog {
  min-width: 75vw;
}

.inventorydash_custom_modal_dialog .modal-content {
  display: flex;
  border: none !important;
  background: var(--canvas_background);
  outline: 3px solid var(--actionbar_background);
  max-width: 1200px !important;
  margin: auto;
  height: var(--modal_height);
  overflow: hidden;
}

.inventorydash_custom_modal_dialog .modal-content button.close {
  position: absolute;
  right: 0;
  z-index: 250;
}

.inventorydash_custom_modal_header {
  display: flex;
  flex-direction: column;
}

.inventorydash_custom_modal_body {
  overflow: auto;
  background: var(--canvas_background);
  /* display: flex;
  flex-direction: column; */
  /* align-self: stretch;
  min-height: auto; */
  height: 100%;
  /* height: calc(100vh - 5rem); */
  max-height: 1000px;
}

.inventorydash_custom_modal_body .overflow_hidden {
  overflow-y: hidden !important;
}

.inventorydash_custom_modal_footer {
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 576px) {
  div .modal .show {
    margin-left: 0;
  }

  .inventorydash_custom_modal_dialog {
   position: absolute;
   width: calc(100% - 6px);
   margin-left: 3px;
   bottom: calc(2rem - 3px); /*hides rounded corners at the bottom of the modal*/
    }

    .inventorydash_custom_modal_body {
      padding-bottom: 3px; /*adds padding because inventorydash_customer_modal_dialog moved position 3px down to hide rounded corners*/
    }

}

.modal {
  overflow: hidden !important;
}
.custom_modal_header button.close {
  position: absolute;
  top: 0;
  right: .25rem;
}
.custom_modal_dialog {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0;
  width: 100%;
}
.custom_modal_dialog .modal-content {
  background: var(--actionbar_background);
  padding: 2px;
  margin: auto;
  width: 90%;
  max-width: 75rem;
  max-height: 50rem;
  overflow: hidden;
}
.custom_modal_body {
  overflow-y: auto;
  overflow-x: hidden;
}
.modal_body_with_tab_header {
  /*this is the body style for modals with tabs as part of the header*/
  background: var(--canvas_background);
  overflow-y: auto;
  /* max-height: 40rem; */
}
/*Different size modals determines the max-width and set height*/
.modal_large .modal-content{
  margin: auto;
}

/*This additional class is used to keep the height of modals when rendering
between components*/
.modal_height_spacer {
  min-height: 35rem;
  max-height: 50rem;
  background: var(--canvas_background);
}
.modal_div {
  height: var(--modal_height);
}

@media only screen and (max-width: 576px) {
  .modal {
    padding-left: 0;
  }

  .custom_modal_dialog {
    position: absolute;
    height: 95vh;
    bottom: 0;
    margin: 0;
  }

  .inventorydash_custom_modal_dialog .modal-content {
    border-radius: .3rem .3rem 0 0;
  }

  .custom_modal_dialog .modal-content {
    min-height: 80vh;
    min-width: 96vw;
    max-height: 80vh;
    margin: auto 0 0 0;
    background: var(--actionbar_background);
    padding: 2px;
    width: 100vw;
    bottom: 0;

  }

  .custom_modal_body {
    background: var(--canvas_background);
    overflow-y: auto;
    min-height: calc(80vh - 4.25rem);
    max-height: calc(80vh - 4.25rem);
  }

  .modal_body_with_tab_header {
    /*this is the body style for modals with tabs as part of the header*/
    min-height: calc(80vh - 7.5rem);
    max-height: calc(80vh - 7.5rem);
    background: var(--canvas_background);
    overflow-y: auto;
  }
}

@media only screen and (max-height: 576px) {

  .inventorydash_custom_modal_dialog {
    position: relative;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    /* min-width: calc(100% - 6px); */
    width: 100%;
    min-width: 100%;
    /* max-width: 700px; */
    height: 100%;

    /* height: calc(100% - 6px); */
    /* max-height: calc(100% - 3rem); */

  }

 .inventorydash_custom_modal_dialog .modal-content {
   /* margin: auto; */
   /* margin-left: 30rem; */
   width: calc(100% - 6px);
   height: calc(100% - 6px);
   max-height: calc(100% - 6px);;
  /* height: calc(100vh - 3rem);
  max-height: calc(100% - 3px); */
  /* width: calc(100vw - 6px); */
  max-width: 700px !important;
 }
 .modal_body_with_tab_header {
   height: calc(70vh - 5.5rem) !important;
   /*overrides .custom_modal_body class when both are used*/
 }
}

.modal_body_with_fields_header_and_fields_footer {
  height: calc(90vh - 19.75rem);
  max-height: 33.5rem;
  overflow-x: hidden;
}
.custom_modal_footer {
  height: 5.5rem;
  background: var(--table_column_header_background);
  border-radius: 0 0 5px 5px;
}

@media only screen and (max-width: 576px) {
  .modal_body_with_fields_header_and_fields_footer {
    /* min-height: calc(100% - 2rem) !important; */
   /* min-height: calc(70vh - 13.5rem);
   max-height: calc(70vh - 13.5rem); */
  }
  .custom_modal_footer {
    height: 5.75rem;
    border-radius: 0;
  }
}

@media only screen and (max-width: 576px) and (max-height: 576px) {
  .inventorydash_custom_modal_dialog {
    position: relative;
    height: calc(100% - 2rem);
  }

  .inventorydash_custom_modal_dialog .modal-content {
    margin-top: 2rem;
    /* height: calc(100% + 6px); */
  }

  .inventorydash_custom_modal_body {
    padding-bottom: calc(2rem + 3px);
  }
}


  .custom_modal_body {
    /* height: calc(70vh - 2.5rem); */
  }


/****************
Different sizes*/
.modal_small .modal-content {
  max-height: 13rem ;
  max-width: 50rem;
}

.modal_med .modal-content {
  max-height: 30rem;
  max-width: 55rem;
}

@media only screen and (max-height: 576px) {
  .modal_small .custom_modal_body {
    height: calc(11rem);
  }
}

/********************************************
          BUTTONS
------------------------------------------*/
.btn-primary {
  background: var(--button-primary-color);
  color: var(--primary-font-color);
}

.btn-primary:focus,
.btn-primary:active {
  box-shadow: none !important; /*Removes glow from REACT styles for most buttons*/
  background: var(--button_small_background_hover) !important;
  color: var(--button_small_font_hover) !important;
  border-color: var(--button_small_border_hover) !important;
}

.btn-primary:disabled {
  box-shadow: none !important; /*Removes glow from REACT styles for most buttons*/
  border-color: var(--button_small_border_hover) !important;
  background: var(--button_small_background);
  color: var(--button_small_font_hover);
}


.btn-primary:disabled:hover {
  box-shadow: none !important; /*Removes glow from REACT styles for most buttons*/
  border-color: var(--button_small_border_hover) !important;
  background: var(--button_small_background);
  color: var(--button_small_font_hover);
}

.show > .btn-primary.dropdown-toggle {
  /*these are the dropdown button (for instance to add a unit of measure to items)*/
  background: var(--button_small_background_hover) !important;
  color: var(--button_small_font_hover) !important;
}


/*********************************************
          CARD STYLES
--------------------------------------------*/
div.card-body {
  background: var(--canvas_background);
  color: var(--canvas_font);
  padding: 0;
}

div.card.border-primary {
  border: none;
  background: var(--canvas_background);
}

/*can be found in form modals to separate the sections within the form*/

div.card-header {
  color: var(--table_column_header_font);
  border-top: 1px solid var(--table_column_header_border);
  border-bottom: none;
  margin: 1.5rem 0 1rem 0;
  height: 2rem;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  background: var(--table_column_header_background);
  width: calc(100% + 2rem);
  position: relative;
  left: -1rem;
}
/*******************************************
          TAB STYLES
------------------------------------------*/
.tab_content {
  padding: 1rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.tab-container {
  height: 100%;
}

.tab-pane {
  height: 100%;
}

.item_tabs {
  border: none;
  display: flex;
  align-items: center;
  z-index: 999;
  background: var(--canvas_background);
}

.item_tabs .nav-item {
  margin: 0 1px;
  background: var(--tab_background);
  border-radius: 0;
  height: var(--tab_height);
}

.item_tabs .nav-link {
  border-radius: 0;
  color: var(--tab_font);
  height: var(--tab_height);
}

.item_tabs .nav-link.active {
  background: var(--canvas_background);
  color: var(--tab_active_font);
  font-weight: bold;
  border: none;
}

.tab_nav_link:focus {
  outline-color: var(--tab_focus_border) !important;
  border-color: var(--tab_focus_border) !important;
}

@media only screen and (max-width: 575px) {
  div.div_tab_container {
    height: 2.5rem;
    display: flex;
    align-items: center;
  }

  .tab_item a.nav-link,
  a.nav-link.active {
    padding: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item_tabs {
    background: var(--canvas_background);
    top: 0;
  }

  .item_edit_tab_container {
    min-height: calc(60vh);
    top: 0;
  }

  a.nav-link.active {
    box-shadow: none;
  }
}

div.item_tabs.nav.nav-tabs.nav-justified {
  width: 100%;
  position: sticky;
  top: 0rem;
}

@media only screen and (max-width: 575px) {
  .tab_content {
    /* height: calc(100% - 3rem); */
  }
}
/*********************************************
          SCROLL BARS
--------------------------------------------*/



/* Scrollbar style for Chrome */


/* Track */

  ::-webkit-scrollbar{
    background: var(--canvas_background);
    width: 15px;
    border: 1px solid var(--button_small_background);
  }

  ::-webkit-scrollbar-track{
    border:  3px transparent solid;
    border-radius: 1000px;

  }

  ::-webkit-scrollbar-thumb{
    border-radius: 100px;
    border:  2px transparent solid;
    box-shadow: inset 0 0 0px 100px var(--scrollbar_thumb);
    min-height: 2.5rem;
  }

   ::-webkit-scrollbar-button{
    height: 15px;
     border-radius: 100px;
    border: 4px transparent solid;
    box-shadow: inset 0 0 0px 100px var(--scrollbar_button);
    background: var(--column-header-background);
  }


/* @media only screen and (max-width: 1000px) {
  ::-webkit-scrollbar{
    background: transparent;
    width: 15px;
  }
} */
/*******************************************
           SEARCH INPUT
------------------------------------------*/
@media only screen and (max-width: 1300px) {
  .search-input {
    min-width: 600px;
  }
}

.numericUpDownContainer {
  display: grid !important;
  grid-template-columns: 2rem 1fr 2rem !important;
  max-width: 200px;
}

.numericUpDownContainer svg {
  margin-bottom: .25rem;
}

.form_control_increase_decrease {
  /* display: flex;
  flex-direction: row ;
  justify-content: center !important;
  align-items: center;
  width: 20px;
  border: 1px solid blue;
  flex-wrap: wrap;
  margin: auto; */

  /* display: grid ;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr; */

  justify-content: center;
  gap: none;
  border-right: 1px solid  var(--field_border);
  border-top: 1px solid  var(--field_border);
  border-bottom: 1px solid  var(--field_border);
  background: var(--button_small_background);
  border-radius: 0 3px 3px 0;
}

.increment-button {
  /* grid-row-start: 1;
  grid-row-end: 2; */
  /* height: 100% !im
  portant; */
 grid-column-start: 1 !important;
 grid-column-end: 2;
}


/* .input_form_container {
  display: grid;
 
} */




.decrease-button {
 /* border-radius: 13px 0 0 13px; */
 -webkit-border-top-left-radius: 100%;
 -webkit-border-bottom-left-radius: 100%;
 -moz-border-radius-topleft: 100%;
 -moz-border-radius-bottomleft: 100%;
 border-top-left-radius: 100%;
 border-bottom-left-radius: 100%;
}

.numericUpDown_input {
  /* grid-column-start: 2;
  grid-column-end: 3; */
}

.numericUpDown_input {
 /* grid-column-start: 2;
 width: 100% !important;
 margin: 0;
 margin-left: -1rem; */
min-width: 100% !important;
}

.increment-button {
  grid-column-start: 3;
  grid-column-end: -1;
  -webkit-border-top-right-radius: 100%;
-webkit-border-bottom-right-radius: 100%;
-moz-border-radius-topright: 100%;
-moz-border-radius-bottomright: 100%;
border-top-right-radius: 100%;
border-bottom-right-radius: 100%;
}


.increment-button, .decrease-button,
.increment-button:hover, .decrease-button:hover,
.increment-button:active, .decrease-button:active,
.increment-button:focus, .decrease-button:focus
 {
  /* background: var(--button_small_background) !important; */
  /* border: 1px solid var(--button_small_border) !important; */
  /* height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0;
  background: transparent !important;
  margin: 0;
  border: 1px solid transparent !important; */
  width: 2rem;
  padding: 0;
  cursor: pointer;
  background: var(--button_small_background) !important;
  border: 1px solid var(--button_small_border) !important;


  font-size: 1.5em;
  font-weight: 500;
  z-index: 99;
  color: var(--button_small_font) !important;
}

.increment-button svg, .decrease-button svg {
  /* color: var(--button_small_font);
  background: var(--button_small_background) !important; */
  
  
}

/* .decrease-button {
  border-radius: 50% 0 0 50%;

  font-weight: 600;
}

.increment-button {
  border-radius: 0 50% 50% 0;
  margin-left: -1rem;
  
} */

.decrease-button svg {
  margin-left: 1px;

}
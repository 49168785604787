
.side-drawer {
    height: 100%;
    background: var(--side_drawer_background);
    position: fixed;
    top: var(--mobile-toolbar-height);
    left: 0;
    width: 80%;
    max-width: var(--sidebar-width);
    z-index: 200; 
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid var(--side_drawer_border);
    border-top: 1px solid var(--side_drawer_border);
    border-bottom: 1px solid var(--side_drawer_border);
}

.side-drawer.open {
    transform: translateX(0);
}


.menu__items a {
    border: none;
    padding: .0rem .6rem;
    width: 100%;
    color: var(--side_drawer_font);
    text-decoration: none;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0;
    /* border-bottom: 1px solid var(--side_drawer_link_border); */
    overflow: hidden;
    align-items: center;
}

.menu__items a:hover, 
.menu__items a:active,
.menu__items a:focus {
    background-color: var(--primary-color);
    /* border: none; */
}

.logout-button {
    /* position: fixed; */
    height: 64px;
    width: 90%;
    top: calc(100% - var(--toolbar-height) - 76px);
    color: var(--side_drawer_font);
    padding: .5rem .1rem;
    margin: .5rem .5rem;
    background: transparent;
    font-size: x-large;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
}

.logout-button:focus,
.logout-button:active,
.logout-button:hover,
.logout-button:visited,
.logout-button:focus-within
.logout-button:active:focus {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    border-color: transparent !important;
    transition: none !important;
    background-color: transparent !important;


}


.logout-text {
    padding: 0rem .1rem;
    margin-top: 0;
    float: left;
    font-size: .8rem;
    width: 58px;
    align-items: flex-start;
}


@media only screen and (min-width: 577px) {

    .side-drawer {
        display: none;
    }
}
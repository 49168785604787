/* .customer_modal {
  background: transparent;
}
.customer_modal .modal-content {
  background: var(--actionbar_background);
  height: 650px;
  padding: 2px;
  max-height: calc(98vh - 3rem);
}
.customer_body {
  height: calc(644px - var(--actionbar-height));
  border-radius: 0 0 4px 4px;
  max-height: calc(98vh - 6.4rem);
} */

/* 
@media only screen and (max-height: 576px) {
  .customer_modal .modal-content {
    position: absolute;
    top: 0;
    min-height: 98vh;
  }
  .customer_body {
    max-height: calc(98vh - 2.5rem);  
    min-height: calc(98vh - 2.5rem);  
  }
}
@media only screen and (max-width: 576px) {
  .customer_modal .modal-content {
   min-height: 90vh;
    position: absolute;
  }
} */

.contact_modal_body {
  height: calc(90vh - 15rem + var(--tab_height)); 
}

.tab-content {
  overflow-y: auto;
  height: calc(var(--modal_height) - var(--actionbar-height) - var(--tab_height)); 
}

@media only screen and (max-height: 576px) {
  .tab-content{
    min-height: 100%;
  }
}


@media only screen and (max-height: 567px) {
  .contact_modal_body {
    height: calc(70vh - 5.5rem + var(--tab_height));
  }
}

.user_modal .modal-content {
  height: 430px;
  margin: auto;
  max-width: 500px;
  /* position: absolute; */
  left: 0; right: 0;
}



@media only screen and (max-width: 576px) {
  .user_modal .modal-content {
    max-height: 90vh;
    min-height: 90vh;
    margin-top: 10vh;
    width: 96%;
  }
}
.locationsAndItemGroups_page {
    padding: 0;
    /* overflow: hidden; */
}

.locationAndItemGroups_PageContainer {
    overflow: hidden;
}


.item_location_page_container .list-group {
    overflow: auto;
}

div.tree-control-outer-container {
    height: calc(100% - .5rem);
    padding-top: 1rem;
    border-top: none;
}

.tree-control-add-item {
    cursor: pointer;
}

.no_margin_no_padding {
    margin: 0;
    padding: 0;
    height: 100%;
}

.locationAndGroup_tab_content {
    height: 100%;
    margin: 0;
    padding: 0;
}
/* 
.item_group_page_container {
    margin-top: 0rem;
    height: calc(100vh - var(--actionbar-height) - 7rem);
    border-top: 2px solid var(--canvas_background);
} */



/* .item_location_page_container {
    margin-top: 0rem;
    height: calc(100vh - var(--actionbar-height) - 7rem);
    border-top: 2px solid var(--canvas_background);
} */

@media only screen and (max-width: 1300px) {
    .locationsAndItemGroups_page {
      /* margin-top: 4rem ; */
      height: 100%;
    }

    .item_group_page_container {
        height: calc(100vh - var(--actionbar-height) - 8rem);
        border-top: 4px solid var(--canvas_background);
    }

    div.tree-control-outer-container {
        height: calc(100% - 1.5rem);
    }
}

@media only screen and (max-width: 576px) {
    .locationsAndItemGroups_page {
        margin-top: 2.5rem;
        margin-bottom: 3rem;
        /* margin-top: 2rem;; */
        /* max-height: 70%; */
        padding-bottom: 1rem;
    }
    .item_location_page_container {
        height: calc(100vh - 9rem)
    }

    .item_group_page_container {
        padding-bottom: 1rem;
    }

    div.tree-control-outer-container {
        height: calc(100% - 9.5rem);
    }
}
.dash-modal {
  position: fixed;
  height: 85vh;
  width: 70%;
  z-index: 5000;
  margin: auto;
  opacity: 0;
  max-height: 1px;
  max-width: 1px;
  transition: all 1.2s ease-in-out;
  top: 25%;
  left: 50%;


  /* top: -200px; */
  /* top: calc(var(--toolbar-height) + .5rem);
  left: 5600px */

  max-height: 85vh;
  max-width: 70%;
  top: calc(var(--toolbar-height) + .5rem);
  left: 15%;

  transform: translateX(600px);
}

.dash-modal.show {
  opacity: 1;
  max-height: 85vh;
  max-width: 70%;
  top: calc(var(--toolbar-height) + .5rem);
  left: 15%;

  transform: translateX(0);
}



/* Just some styling for the modal */
.dash-modal-container {
    background: var(--canvas_background);
    border: 1px solid black;
    
}
.dash-modal-header {
    height: 3rem;
    border: 1px solid black;
    padding: .5rem;
}

.dash-modal-body {
    min-height: 70vh;
    padding: .5rem;
}
.dash-modal-x-button {
    float: right;
}
.inventorydash_cb_container {
    margin-top: .2rem;
    margin-left: .1rem;
    /* background: red; */
}

.inventorydash_custom_checkbox {
    display: inline-flex;
    position: relative;
    color:blue;
}


/* This controls the focus, visual cue of the checkbox receiving focus */
.inventorydash_custom_checkbox > input:focus {
  outline-style: solid;
  outline-color: var(--inventorydashcb_border_color);
  outline-width: thin;
}


.inventorydash_custom_checkbox > span {
    color: var(--inventorydashcb_fore_color);
    opacity: 1;
    /* padding: 0.2rem 0.3rem; */
    padding-top: .2rem;
    padding-left: .3rem;
    padding-right: 0;
    padding-bottom: 0;
}

.inventorydash_custom_checkbox > input {
    height: 1.0rem;
    width: 1.0rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid var(--inventorydashcb_border_color);
    /* border: 1px solid green; */
    border-radius: 10%;
    outline: none;
    transition-duration: .3s;
    background: var(--inventorydashcb_background_color) ;
    /* background: yellow ; */
    margin-top: .45rem;
}

.inventorydash_custom_checkbox > input:checked {
    border: 1px solid var(--inventorydashcb_border_color);
    background: var(--inventorydashcb_background_color) ;
    /* border: 1px solid yellowgreen;
    background: blue ; */
}

/* This is the actual check */
.inventorydash_custom_checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    position: absolute;
    left: 0.1rem;
    top: .2rem;
    font-weight: bold;
    color: var(--inventorydashcb_check_color);
    /* color: #000; */
}

.inventorydash_custom_checkbox > input:active {
    /* border: 2px solid #34495E;ss */

    background: var(--inventorydashcb_toggle_bgcolor);
    /* background: red; */
}

.inventorydash_custom_checkbox_disabled_text {
    
    /* color: hsl(0, 1%, 70%); */
    /* color: hsl(var(--inventorydashcb_fore_color), 10%); */
    /* filter: brightness(40%); */
    opacity: 0.5 !important;
}
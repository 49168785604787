.return_modal {
  overflow-x: hidden;
  overflow: hidden;
}

.nextIcon {
  color: var(--actionbar_font);
}

.return_topRow {
  margin: 0;
  width: 500px;
  padding: .5rem .5rem 0 .5rem;
}


.ReturnHeader {
  padding: 0 ;
  background: var(--table_column_header_background);
  color: var(--table_column_header_font);
  height: 2.5rem;
  font-size: 16px;
  
}
.ReturnHeader .headerCol {
  margin-top: .45rem;
  font-weight: 600;
}

.masterCheckbox {
  max-width: 2rem;
}

.returnModalBody {
  height: 100%;
  max-height: calc(100% - 6rem);
}

.returnModalBody .windowed_table_container_div {
  padding-top: 0;
}

.returnModalBody .padding {
  padding: .5rem;
}

.return_topRow .form-group {
  width: 350px;
  
}

.filterBar {
  margin-top: 0;
  position: relative;
  top: -.6rem;
  left: -.5rem;
}

.search_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 576px) {
  .return_topRow {
    width: 350px;
    padding: .5rem .5rem 0 .5rem;
  }

  .returnModalBody {
    height: 100%;
  }

  .return_topRow .form-group {
    width: 350px;
    max-width: 90%; 
  }
}
.search_modal_header .close {
    position: absolute;
    right: .5rem;
    top: 0;
    z-index: 9;
}

.search_modal_header {
    z-index: 999;
    display: flex;
    flex-direction: column;
    background: transparent;
    display: flex;
    justify-content: space-evenly;
    background: var(--actionbar_background);
}

.search_modal_search {
    width: 100%;
}

.barcode_scanner_icon {
    color: var(--canvas_font);
    height: 100%;
}

.search-results {
    padding: .5rem;
}


@media only screen and (max-width: 576px) {
    .search_modal_header {
        height: 10rem;
    }

    .modal_search_body {
        min-height: calc(80vh - 10.45rem);
        max-height: calc(80vh - 10.45rem);
    }

    .search_links_header {
        padding: .5rem;
    }
}
/*divs*/
div
.actionbar_container {
    display: flex;
    margin: 0;
    
}


div
.actionbar_row,
.actionbar_row_modal {
    min-width: 100%;
    display: flex;
    background: var(--actionbar_background);
    align-items: center;
    padding: 0;
    height: var(--actionbar-height);
    /* position: absolute; */
    z-index: 1;
}

.actionbar_row_modal {
    border-radius: 5px 5px 0 0;
    /* border-bottom: 1px solid var(--actionbar_border); */
}

.actionbar_modal_header {
    height: 2rem;
    height: 3rem;
    min-width: 100%;
    display: flex;
    align-items: center;
   background: var(--actionbar_background);
   color: var(--actionbar_font);
   padding-right: 1.5rem;
}

.actionbar_title_div {
    display: flex;
    flex-wrap: nowrap;
    min-width: max-content;
}

div.actionbar_save_div {
    display: flex;
    align-items: center;
}

/*button styles that override react default styling*/
.actionbar_add_btn,
.actionbar_edit_btn,
.actionbar_save_btn,
.actionbar_add_btn:focus,
.actionbar_edit_btn:focus,
.actionbar_save_btn:focus,
.actionbar_add_btn.active,
.actionbar_edit_btn:active,
.actionbar_save_btn:active,
.actionbar_del_btn,
.actionbar_del_btn:hover,
.actionbar_del_btn:active,
.actionbar_del_btn:focus
 {
    width: 7rem;
    height: 2.3rem;
    /* margin-right: 1rem;
    margin-left: 1rem; */
    /* display: flex; */
    display: block;
    /* align-items: center; */
    /* justify-content: space-evenly; */
    background: var(--button_small_background) !important;
    color: var(--button_small_font) !important;
    border: 1px solid var(--button_small_border) !important;
    outline: none !important;
    box-shadow: none !important;
}

.actionbar_add_btn svg {
 position: relative;
 top: 0;
 height: 1.5rem;
 margin-bottom: .15rem;
}

.small_button_edit_icon {
    margin-left: .5rem;
}

.cancel_exit_button svg {
    color: var(--button_small_background);
}

.cancel_exit_button svg:hover, 
.cancel_exit_button svg:active {
    color: var(--actionbar_font);
}


.actionbar_add_btn:hover,
.actionbar_edit_btn:hover,
.actionbar_save_btn:hover,
.actionbar_del_btn:hover {
/* background: var(--button_small_hover_background) !important; */
border: 1px solid var(--button_small_hover_border) !important;
background: var(--button_small_hover_background) !important;
color: var(--button_small_hover_font) !important;
}

.actionbar_add_btn_div,
.actionbar_edit_btn_div,
.actionbar_save_div,
.actionbar_del_div {
    padding: 5px;
}

.actionbar_email_div, .actionbar_print_div {
    margin: 0 .5rem;
    display: flex;
    align-items: center;
}

.actionbar_help_div  {
    display: flex;
    justify-self: center;
    align-items: center;
}

.actionbar_sort_btn_div {
    min-height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.actionbar_btn_container {
    display: flex;
    width: 100%;
    margin: 0;
}

.actionbar_title_div, .actionbar_title_only_div {
    padding: 0;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: flex-start;
    width: auto;
    padding: 0;
    margin: 0;
    height: 100%;
}

div.actionbar_page_title,
.actionbar_page_title_only {
    color: var(--actionbar_font);
    font-size: 1.5em;
    margin: 0 .5rem;
}

/*title*/
h2.actionbar_page_title_only {
  min-width: 100%;
}


/*buttons*/
.actionbar_cancel_back_btn {
    max-width: min-content;
    border: none !important;
    outline: none !important;
}

.cancel_exit_button {
    border: none !important;
    height: 3rem;
    border-radius: 0;
}

.cancel_exit_button:hover,
.cancel_exit_button:active,
.cancel_exit_button:focus {
    color: var(--actionbar_button_back_hover);
    background: transparent !important;
    max-height: 100% !important;
    outline: none !important;
}

.cancel_exit_button {
    color: var(--actionbar_cancel_button);
    padding: 0;
}

.actionbar_save_btn {
    align-self: flex-end;
    margin-left: auto;
    /* display: flex; */
    display: block;
    justify-content: center;
}


/*keeps same style after button is clicked
prevents it from not aligning to the right*/
.actionbar_save_btn:active,
.actionbar_save_btn:focus {
    align-self: flex-end;
    margin-left: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    outline-style: solid;
}


.actionbar_btn_container { /*adds margin to avoid running into modal close button*/
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-right: .5rem;
}

button.actionbar_col_btn,
button.actionbar_help_btn,
button.actionbar_help_btn:hover,
button.actionbar_help_btn:focus,
button.actionbar_help_btn:active,
button.actionbar_col_btn:hover,
button.actionbar_col_btn:active,
button.actionbar_col_btn:focus {
    background: transparent;
    border: 1px transparent;
    outline: none;
    box-shadow: none;
    color: var(--actionbar_font);
    padding: 0;
    margin: auto .5rem;
    height: 100%;
    width: 2rem;
}

.actionbar_print_div, .actionbar_email_div {
    color: var(--actionbar_font);
}

.actionbar_settings_div {
    color: var(--actionbar_font);
    display: flex;
    align-items: center;
}

.actionbar_settings_div:hover,
.actionbar_print_div, .sort_icon {
    cursor: pointer;
}

.actionbar_btn_container_modal {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

@media only screen and (max-width: 1300px) {
    .actionbar_row_modal {
        margin-top: 0;
    }

    .modal-header > .actionbar_row { /*prevents actionbars on modals to increase in height*/
        margin: 0 !important;
    }

    .actionbar_title_div {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        min-width: max-content;
    }

    .actionbar_page_title_only {
        width: auto;
        max-height: 1.2em;
    }

    .actionbar_page_title_only {
        width: 100%;
    }

    .actionbar_btn_container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-right: 0;
    }

    .actionbar_btn_container_modal {
        display: flex;
        width: 100%;
        margin-right: 0;
        justify-content: flex-end;

    }
}

@media only screen and (max-height: 576px) {  /*For mobile screens held landscape orientation*/
    .actionbar_modal_header {
        max-height: 2rem;
    }

    .actionbar_btn_container_modal {
        height: 2rem;
        margin: auto 0;
        display: flex;
        align-items: center;
        position: relative;
        top: -2px;
    }

    .actionbar_add_btn,
    .actionbar_edit_btn,
    .actionbar_save_btn,
    .actionbar_add_btn:active,
    .actionbar_edit_btn:active,
    .actionbar_save_btn:active,
    .actionbar_add_btn:focus,
    .actionbar_edit_btn:focus,
    .actionbar_save_btn:focus,
    .actionbar_del_btn,
    .actionbar_del_btn:active,
    .actionbar_del_btn:focus {
        max-height: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}



@media only screen and (max-width: 576px) {

    div.actionbar_row {
        padding: 0;
        margin: 0;
        height: 3rem;
    }

    .actionbar_help_div_modal {
        margin-bottom: .3rem;
    }

    .actionbar_footer {
        position: absolute;
        bottom: 0;
        max-width: 94%;
        margin: 0 auto;
        max-height: 2.5rem;
    }

    div.actionbar_save_div {
        width: fit-content;
        margin-left: 3rem;
    }

    div.actionbar_row {
        padding: 0;
        height: 3rem;
    }

   .actionbar_modal_header {
       max-height: 1.5rem;
   }

    .actionbar_mobile_footer { /*when actionbar is on bottom of screen as footer*/
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 99;
        display: flex;
        left: 0;
        margin: 0;
        height: 2.6rem;
        max-width: 1000px;
    }

    .mobile_modal_footer { /*this div is applied to all modals when the actionbar also needs to be at the bottom*/
        position: fixed;
        bottom: 2.5rem;
        width: 95%;
        z-index: 999;
    }

    .actionbar_page_title_only {
        font-size: 16pt;
    }

    .actionbar_save_btn,
    .actionbar_del_btn,
    .actionbar_add_btn,
    .actionbar_edit_btn,
    .actionbar_save_btn:hover,
    .actionbar_del_btn:hover,
    .actionbar_add_btn:hover,
    .actionbar_edit_btn:hover,
    .actionbar_save_btn:active,
    .actionbar_del_btn:active,
    .actionbar_add_btn:active,
    .actionbar_edit_btn:active,
    .actionbar_save_btn:focus,
    .actionbar_del_btn:focus,
    .actionbar_add_btn:focus,
    .actionbar_edit_btn:focus
    {
        width: 2.3rem !important;
        border-radius: 50%;
        height: 2.3rem !important;
        padding: 0;
        background: var(--actionbar_background) !important;
        align-self: center;
        margin: 0;
        outline: none !important;
        border: none !important;
        display: block;
    }

    .actionbar_btn_container {
        display: flex;
        justify-content: flex-end;
    }

    .small_button_col_icon {
        color: var(--actionbar_font) !important;
    }

    div.actionbar_del_div,
    div.actionbar_save_div,
    div.actionbar_add_btn_div,
    div.actionbar_edit_btn_div,
    div.actionbar_print_div,
    div.actionbar_email_div {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 0;
        min-height: 100%;
        bottom: 0;
        width: 7rem;
        margin: 0;
    }

    .small_button_del_icon,
    .small_button_save_icon,
    .small_button_edit_icon,
    .small_button_add_icon {
    height: 2rem !important;
     color: var(--actionbar_font) !important;
     z-index: 999 !important;
     align-self: center;
   }
}
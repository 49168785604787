.column_selector_list_button_group_div {
    float: right;
}

.column_selector_list_button_group_div .columnBtn:active,
.column_selector_list_button_group_div .columnBtn:focus {
    background: var(--button_small_background) !important;
}

.active_column {
    background: var(--table_selected_item_background) !important;
}

div.list-group-item {
    background: var(--canvas_background);  
    border-right-color: transparent;   
}

.column_selector_list_button_group_div {
    min-width: 7rem;
    display: flex;
    justify-content: space-between;
}

.columnHeader_body {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* top: var(--actionbar-height); */
    max-height: calc(100% - var(--actionbar-height));
}


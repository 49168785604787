.po_lineitem_modal_body {
  height: calc(var(--modal_height) - 0rem);
  background: var(--canvas_background);
}

.purchase-order-line-item-outer-container {
  position: absolute;
  top: 0; left: -1px;
  display: flex;
  flex-direction: column;
  z-index: 101;
  background: var(--field_select_active_background);
  cursor: auto;
  width: calc(100% + 1px);
  border-left: 2px solid var(--actionbar_background);
  border-right: 2px solid var(--actionbar_background);
  height: calc(100% - 6rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.add_edit_lineitem_header {
  background: var(--table_column_header_background);
  height: 2rem;
  display: flex;
}

.add_edit_lineitem_header .title {
  color: var(--table_column_header_font);
    border-bottom: none;
    height: 2rem;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    left: -1rem
}

.add_edit_lineitem_header .exit_icon {
  height: 100%;
  width: 2rem;
}

.add_edit_lineitem_header .btn_container {
  margin: auto 1rem auto auto;
}

.add_edit_lineitem_header .btn_container button{
  margin: auto .5rem;
  border: 1px solid var(--button_small_border);
  border-radius: 3px;
}

.add_edit_lineitem_header button.cancel_btn {
  border-color: transparent;
  background: transparent;
}

.po_lineItem_exit {
  z-index: 999;
  width: 1rem;
  position: absolute;
  right: 0;
}

.purchase-order-view-only-line-item-outer-container {
  display: flex;
  flex-direction: column;
  cursor: zoom-in;
  background: transparent;
  position: absolute;
  height: 4rem;
  width: calc(100% - 1rem);
}

.grid_purchaseorder_row {
  margin: 1rem 1rem 0 1rem;
  min-width: 95%;
  grid-template-columns: 3fr 1fr 1fr;
  display: grid;
  grid-template-rows: 1fr;
  gap: .5rem
}

.purchase-order-line-item-view-detail {
  display: grid;
  grid-template-columns: 3fr repeat(5, 1fr);
  grid-auto-flow: column;
  margin-bottom: 0;
  grid-column-gap: 0.25rem;
  padding: 0.5rem;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  border: 1px solid var(--table_column_header_background);
}

/* Allow Item to Expand With This Control */
.purchase-order-line-item-left-align {
  text-align: left !important;
}

.purchase-order-line-item-view-expander {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-right: 1.5rem;
  cursor: auto;
}

.purchase-order-line-item-right-align {
  text-align: right;
}

.purchase-order-line-item-center-align {
  text-align: center;
}

.purchase-order-line-item-detail-header {
  font-weight: bolder;
  text-align: center;
}

.grid_purchaseorder_price input.form-control {
  width: calc(100% - 2.5rem);
}

.purchaseorderline_item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.purchaseorder_items {
  background: var(--canvas_background);
  border-color: transparent;
}

.purchaseorder_add_item_btn,
.purchaseorder_add_item_btn:active,
.purchaseorder_add_item_btn:focus {
  background: transparent !important;
  border: none !important;
  margin-left: 0.25rem;
  color: var(--canvas_font) !important;
}

.purchaseorde_line_expandLess {
  position: relative;
  bottom: 0rem;
  right: -0.5rem;
  cursor: pointer;
}

.purchaseorder_lineitem_secondrow_grid {
  padding: 0 1rem;
  display: grid;
  column-gap: 0;
  column-gap: 1rem;
  row-gap: 0.25rem;
  grid-template-columns: 12.5rem 12.5rem 1fr;
}

.purchaseorder_lineitem_thirdrow_grid {
  padding: 0 1rem;
  column-gap: 0;
  column-gap: 1rem;
  row-gap: 0.25rem;
  max-width: 25%;
}

.purchaseorder_lineitem_secondrow_grid > div {
  margin: 0 !important;
  padding: 0.35rem 0 0 0;
}

.purchaseorder_checkbox_component {
  padding: 0 !important;
  grid-column-start: 1;
  grid-column-end: 2;
}

.purchaseorder_datepicker_input_field {
  min-width: 50px !important;
  max-width: 100% !important;
}

.purchaseorder_expected_date {
  grid-column-start: 2;
  grid-column-end: 3;
}

.purchaseorder_note {
  grid-column-start: 3;
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: -1;
  height: fit-content;
}


.purchaserorder_qty_recieved {
  font-weight: bold;
}

.purchaseorder_lineitem_history {
  font-weight: 100;
  font-style: italic;
}

.purchaseorder_lineitem_recieved_area {
  border: 1px solid rgba(0,0,0,0.25);
  padding: .5rem;
  border-radius: 10px;
  background: var(--disabled_control_background_color) ;
}

.purchaseorder_lineitem_recieved_area.enabled {
  background: initial ;
}

.purchaseorder_received_date {
  padding-bottom: .5rem;
}

/*****************************************************
*****************************************************/

@media only screen and (max-width: 1000px) {


  .grid_purchaseorder_row {
    margin: 0;
    padding: 1rem 1rem 0 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 1fr 1fr;
  }

  .grid_purchaseorder_name {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .purchaseorder_lineitem_secondrow_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    row-gap: .5rem;
  }

  .purchaseorder_checkbox_component {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .purchaseorder_expected_date {
    grid-column-start: 2;
    grid-column-end: -1;
    grid-row-start: 1;
  }

  .purchaseorder_note {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-column-end: -1;
    /* grid-row-start: 3; */
    grid-column-end: -1;
  }
}

@media only screen and (max-width: 576px) {

  .purchase-order-view-only-line-item-outer-container {
    height: 10rem;
  }

  .purchase-order-line-item-outer-container {
    width: calc(100% + 4px);
    left: -2px;
  }

  .purchase-order-line-item-view-detail {
    height: 10rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-auto-flow: initial;
    grid-template-rows: repeat(3, 1fr);
  }

  .purchase-order-line-item-view-expander {
    top: -2rem;
    height: 1.5rem;
  }

  .purchase-order-line-item-outer-container {
    min-height: 100%;
  }

  .purchaseorder_lineitem_secondrow_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
  }

  .purchaseorder_checkbox_component {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .purchaseorder_expected_date {
    grid-column-start: 2;
    grid-column-end: -1;
    min-width: 100px;
  }

  .purchaseorder_note {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-column-end: -1;
  }

  .datepicker-container {
    min-width: 100px;
  }

  .purchaseorder_datepicker_input_field {
    min-width: 100px;
  }

  @media only screen and (max-width: 450px) {
    .purchaseorder_lineitem_secondrow_grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 5rem 1fr;
      gap: 0.5rem;
    }

    .purchaseorder_checkbox_component {
      grid-column-start: 1;
      grid-column-end: -1;
    }

    .purchaseorder_expected_date {
      grid-column-start: 1;
      grid-column-end: -1;
      min-width: 100px;
      grid-row-start: 2;
    }

    .purchaseorder_note {
      grid-column-start: 1;
      grid-row-start: 3;
      grid-column-end: -1;
    }
  }
}


/* These height settings are found on the app.css file
--sidebar-width: 8.4rem;
--toolbar-height: 4.5rem;
--mobile-toolbar-height: 4.5rem;
--tablet-toolbar-height: 5.5rem;
--actionbar-height: 3em;
*/


/*Toolbar header*/
  .toolbar {
    position: fixed;
    top: 0;
    left: 0; /* var(--sidebar-width);*/
    width: 100%; /* calc(100% - var(--sidebar-width));*/
    background: var(--navbar_background);
    /* #45494a;*/
    height: var(--toolbar-height);
    display: block;
    z-index: 0;
    /*z-index was set to 250 and I (Brandon) changed it to 0 so that the modal overlay would cover this when the modal is opened*/
  }

/*Nav*/
  .toolbar_navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding-right: 1rem;
    padding-left: 1rem;
    border-bottom: 2px solid var(--navbar_border);
    background: var(--navbar_background);

  }
  .toolbar_navigation-items ul {
    list-style: none;
    margin: 0 0 0 .5rem;
    padding: 0;
    display: flex;
    height: var(--toolbar-height);
    align-items: center;
  }
  .toolbar_navigation-items a {
    color: var(--navbar_font);
    text-decoration: none;
    display: inline-block;
    padding: .5rem 0.5rem .25rem .5rem;
  }

  .toolbar_navigation-items a:hover,
  .toolbar_navigation-items a:active {
    border-bottom: 1px solid var(--navbar_link_hover_border);
  }

  .toolbar_navigation-items a:focus {
    border-bottom: 3px solid var(--navbar_link_hover_border);
  }

/*divs that contain buttons/icons*/
.scanner_button_div {
  border: none;
  min-height: 2rem;
  max-height: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 .5rem;
}

svg
.search_icon {
  background: white;
  size: 3rem;
  border-radius: 50%;
  padding: .5rem;
  align-self: center;
}

  div .home_icon_div svg {
    align-self: center;
  }
  div .menu-icons {
    display: flex;
  }

  div.question_icon_div,
  div.home_icon_div,
  div.settings_icon_div,
  div.door_icon_div {
    margin-left: .5rem;
   display: flex;
    align-items: center;
  }

  div .menu-icons,
  div .home-icon {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .home-logout-icons {
    display: flex;
  }

  div .question-icon:hover,
  .settings-icon:hover,
  .home-icon:hover,
  .door-icon:hover {
    background: var(--navbar_link_hover_background);
    border-radius: 50%;
  }

/*spacers*/
  div .icon-spacer {
    width: 90%;
  }

  .settings_icon_div {
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  /*buttons*/
  .search_icon_div,
  .search_icon_div:hover,
  .search_icon_div:active {
    background: none !important;
    border: none !important;
    outline: none !important;
    margin: auto 1rem;
  }

/*icons*/
.toolbar_settings_icon,
 .toolbar_question_icon {
   border-radius: 50%;
   max-height: 1.6rem;
   max-width: 1.6rem;
   min-width: 1.6rem;
   min-height: 1.6rem;
   margin-right: .5rem;
   color: var(--navbar_question_setting_icon);
  }

  .toolbar_home_icon,
  .toolbar_logout_icon {
    color: var(--navbar_home_logout_icon);
    cursor: pointer;
  }

  .scanner_icon  {
    color: var(--navbar_search_button_expanded_scanner_icon);
  }

/*logo*/
  .toolbar_logo_div {
    /* margin-left: 1rem; */
    vertical-align: middle;
    max-height: 3rem;
  }

  .toolbar_logo {
    align-items: center;
    max-height: 2.5rem;
  }

  .toolbar_icons_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }



/****************/
/*TABLET SCREENS - Menu transitions to additional height - searchbox
  flexes to top along with home-icons - nav menu sits below searchbox/home-icons*/
@media (max-width: 1300px) {

  .toolbar_navigation {
    flex-wrap: wrap;
    height: var(--tablet-toolbar-height);
    background: var(--navbar_background);
  }

  div .searchbox {
    width: 60%;
    margin-left: 10px;
    margin-top: 7px;
  }

  div.settings-icon {
    display: flex;
    align-items: center;
  }

  div .menu-icons {
    flex: 2;
    width: 20%;
    margin-top: .5rem;
  }

  div .question-icon {
    padding-left: 15px;
    display: flex;
    align-items: center;
    min-height: 100%;
  }

  div .settings-icon,
  .door-icon {
  display: flex;
  align-items: center;
  }

  div .home_icon_div {
    order: 3;
    display: flex;
    align-items: center;
  }

  .toolbar_navigation-items {
    order: 4;
    width: 100%;
   height: 2rem;
  }

  ul.nav_unordered_list {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-height: 100%
  }

  nav.toolbar_navigation ul li a {
    max-height: 3rem;
    margin-bottom: .5rem;
  }

  nav.toolbar_navigation ul li a:hover {
    background: none;
    border: none;
  }



  /****************/
  /*MOBILE SCREENS - all icons except home icon set to display none,
  nav bar set to display none and menu toggle displays.  Searchbox centered*/
  @media only screen and (max-width: 576px) {
    nav.toolbar_navigation {
      display: flex;
      align-items: center;
      height: var(--toolbar-height);
    }

    div.icon-menu.container {
      height: var(--toolbar-height);
    }

    .toolbar_navigation-items {
    position: absolute;
    top: -9999px;
    left: -9999px;
    }

    div .menu-icons {
      order: 3;
      display: flex;
     margin-right: auto;
      max-height: 2rem;
      margin: 0;
      order: 2;
    }

    .toolbar_home_icon {
      display: flex;
      align-self: flex-start;
      margin-top: auto;
      padding: 0;
      align-items: flex-start;
    }

    div.question_icon_div,
    div.settings_icon_div,
    div.door_icon_div {
    position: absolute;
    top: -9999px;
    left: -9999px;
    }

    div .toggle-button {
      order: 1;
      align-self: center;
      height: 2rem;
    }

    div.scanner_button_large_div {
      max-height: var(--toolbar-height);
      display: flex;
      align-items: center;
    }

    div.toolbar_toggle {
      max-height: 2.5rem;
      margin: 0;
      padding: .5rem 0;
      order: 0;
    }

    .toolbar_logo_div {
      margin-left: 1rem;
    }

    div.home-logout-icons {
      max-height: 2rem;
      display: flex;
     margin-right: 0;
     padding-right: 0;
    }
    .icon-spacer {
      width: 100%;
    }

    .spacer {
      display: flex;
      width: 1rem;
    }

    .toolbar_navigation {
      height: 56px;
      display: flex;
      justify-content: flex-end;
    }

    div.home-icon-div {
      min-height: 100%;
      align-self: flex-end;
      margin-right: auto;
    }

    .search_input_close_button {
      margin-right: .5rem;
    }

    .search_icon_div, .search_icon_div:hover {
      background: white;
      margin-top: .5rem;
      margin: auto .5rem;
    }
  }



  @media screen and (max-width:399px) {
    .scanner_button_large_div {
      margin: 0;
    }

    div.scanner_button_div {
      padding: 0;
      margin: 0;
    }
  }
}

.item_summary_container {
  background: var(--table_column_header_background);
  height: 4rem;
  width: calc(100% + 2rem);
  position: relative;
  right: 1rem;
  bottom: -1rem;
}

.item_summary_container .row {
  height: 50%;
}

.item_summary_container .row .col {
  max-width: 33%;
  display: flex;
  align-items: center;
  height: 100%;
}

.purchaseorder_row {
  border-bottom: 1px solid var(--canvas_background);
}

.purchaseorder_row h4,
.salesorder_row h4 {
  font-size: 1em;
  padding-left: .5rem;
  /* margin: 0; */
  margin: auto 0;
  color: var(--canvas_font);
}

.pending_items {
  justify-content: center;
}

.item_summary_container .btn {
  height: 80%;
  width: 5rem;
  padding: 0;
  margin: auto 0 auto auto;
  /* background: red; */
}


@media only screen and (max-width: 380px) {
  .item_summary_container .btn {
    width: 2rem;
  }
}